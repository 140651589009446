@if (questionsByLang$ | async; as questionByLang) {
  @if (!isMultiple) {
    <mat-form-field class="input" appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select [formControl]="_control" required>
        @for (values of questionByLang; track trackByOptionsChoiceLang(index, values); let index = $index) {
          <mat-option class="choice-option" [value]="values[0].id">
            <div class="group-opt">
              @for (value of values; track trackByOptionsChoiceByLang($index, value)) {
                <div>
                  <span class="fi fi-{{ value.lang | lowercase }} "></span>
                  &nbsp;{{ value.label }}
                </div>
              }
            </div>
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  @if (isMultiple) {
    <mat-form-field class="input" appearance="outline">
      <mat-select [formControl]="_control" multiple required>
        @for (values of questionByLang; track trackByOptionsChoiceLang(index, values); let index = $index) {
          <mat-option class="choice-option" [value]="values[0].id">
            <div class="group-opt">
              @for (value of values; track trackByOptionsChoiceByLang($index, value)) {
                <div>
                  <span class="fi fi-{{ value.lang | lowercase }} "></span>
                  &nbsp;{{ value.label }}
                </div>
              }
            </div>
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
}
