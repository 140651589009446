import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { environment } from '@environment';
import { RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { WINDOW } from '@wizbii-utils/angular/core';
import { WebVitalsOptions, WebVitalsParams, sendWebVitals } from '@wizbii/utils';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();

  /**
   * Use class `hover-on` in CSS as follows:
   * `:host-context(.hover-on) .link:hover { ... }`
   */
  @HostBinding('class.hover-on') hoverEnabled = true;

  @HostBinding('class.hover-off')
  get hoverDisabled(): boolean {
    return !this.hoverEnabled;
  }

  /**
   * Class `focus-off` disables all outlines automatically.
   */
  @HostBinding('class.focus-off') focusOutlineDisabled = false;

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Inject(WINDOW) private readonly window: any,
    private readonly store: Store
  ) {
    if (!isPlatformBrowser(platformId)) {
      return;
    }

    /**
     * Disable hover on `touchstart` to cover browsers that do not support pointer events.
     * https://caniuse.com/#feat=pointer
     */
    fromEvent(this.window, 'touchstart', { passive: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.hoverEnabled = false;
      });
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.document.documentElement.lang === '') {
      this.document.documentElement.lang = 'fr';
    }

    const routerState = this.store.selectSnapshot(RouterState)?.state;
    if (routerState?.url) {
      const params: WebVitalsParams = {
        params: routerState.params,
        path: routerState.url,
        applicationId: environment.applicationId,
        envFqdn: environment.domain.wizbii,
      };
      const options: WebVitalsOptions = {
        dev: environment.platform === 'local',
        debug: environment.platform === 'local',
        browser: isPlatformBrowser(this.platformId),
      };
      sendWebVitals(params, options);
    }
  }

  /**
   * Enable hover if "mouse" pointer event is detected; disable it otherwise.
   * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
   */
  @HostListener('pointerenter', ['$event'])
  onPointerEnter(event: PointerEvent): void {
    const evt: PointerEvent = event; // https://github.com/kulshekhar/ts-jest/issues/1035
    this.hoverEnabled = evt.pointerType === 'mouse';
  }

  @HostListener('mousedown')
  onMouseDown(): void {
    this.focusOutlineDisabled = true;
  }

  @HostListener('keydown.Tab')
  onTabKeyDown(): void {
    this.focusOutlineDisabled = false;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
