import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slugStringify',
})
export class SlugStringifyPipe implements PipeTransform {
  transform(values: string[] | string): any[] | any {
    if (!Array.isArray(values)) {
      const v = values.replace(/-/g, ' ');
      return v.charAt(0).toUpperCase() + v.slice(1);
    }
    return values.map((v) => v.replace(/-/g, ' ')).map((v) => v.charAt(0).toUpperCase() + v.slice(1));
  }
}

@NgModule({
  declarations: [SlugStringifyPipe],
  exports: [SlugStringifyPipe],
})
export class SlugStringifyPipeModule {}
