<svg class="visually-hidden" xmlns="http://www.w3.org/2000/svg">
  <!-- Logo -->
  <symbol id="icon-logo" fill="none" viewBox="0 0 1852.5265 1431.5159">
    <path
      fill="#8782ff"
      fill-rule="evenodd"
      d="M1852.5264 355.54156L1513.808 1.86573 355.50989.00001-.00004 355.54156l893.51292 1075.97437z"
      clip-rule="evenodd"
    />
    <path
      fill="#ffffff"
      fill-rule="evenodd"
      d="M155.40325 361.91884l236.55688-244.46251 148.61491 1.8341c-229.484 110.9526-285.0868 261.5808-247.17149 398.82216zm523.25643 629.2443c183.73689 50.34296 297.1244 58.63856 460.52812 8.4959L903.91683 1246.2297zM366.98895 637.87729l191.77954 215.36987c29.13479-112.81831 332.11989-43.86031 358.70382 48.52989 126.99569 41.78378 343.40909-29.31399 346.43429-95.53137-.5903-159.72492-738.14139-27.20585-896.91765-168.36839zM1499.7889 157.00546l-5.1439 227.83965h-298.7477c4.0477-150.63874-110.4255-224.61416-330.81282-218.54265-155.18184 3.03576-304.29216 63.68758-306.31599 163.77259 0 237.57936 835.54901-19.20537 902.27241 312.3875l242.3122-280.21694zm-355.3518-36.87176l158.4811.18973-34.732 117.95169c-29.2086-71.414-78.3815-97.48142-123.7491-118.14142z"
      clip-rule="evenodd"
    />
  </symbol>

  <symbol id="icon-logo-mini" fill="none" viewBox="0 0 43 29">
    <path
      fill="currentColor"
      d="M25.38 28.03h7.16L42.69 0h-7.73l-9.58 28.03zM20.08 5.88L15.23 21.8 7.96 0H0l10.27 28.03h9.57l7.62-22.15h-7.38z"
    />
  </symbol>

  <!-- Experience icon set -->

  <symbol id="icon-exp-search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.69 20.53">
    <path
      d="M17.69 20.53a1 1 0 0 1-.75-.34l-3.86-4.37A8.53 8.53 0 0 1 .16 10.23 8.57 8.57 0 0 1 8.75 0a8.63 8.63 0 0 1 8.35 7.91 8.54 8.54 0 0 1-.52 3.67 1 1 0 0 1-1.87-.71 6.57 6.57 0 0 0-6-8.87 6.46 6.46 0 0 0-5.16 2.33 6.57 6.57 0 0 0 8.5 9.8 2 2 0 0 1 2.52.37l3.86 4.37a1 1 0 0 1-.75 1.66z"
    />
  </symbol>

  <symbol id="icon-exp-act-left" fill="none" viewBox="0 0 30 30">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M26.44 23.76c-.7 0-1.25-.56-1.25-1.25a7.5 7.5 0 00-7.5-7.5H4.94L11 20.32a1.25 1.25 0 01-1.65 1.89l-7.5-6.56a1.79 1.79 0 011.18-3.13h14.66a10 10 0 0110 10c0 .69-.57 1.24-1.25 1.24zM6.73 10.95L11 7.2A1.25 1.25 0 009.36 5.3L5.08 9.06a1.25 1.25 0 101.65 1.89z"
      clip-rule="evenodd"
    />
  </symbol>

  <symbol id="icon-exp-arrow-down" fill="none" viewBox="0 0 24 24">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5388 24C12.3576 23.9983 12.1782 23.9645 12.0088 23.9C11.4125 23.6924 11.0118 23.1314 11.0088 22.5V1C11.0088 0.447715 11.4565 0 12.0088 0C12.5611 0 13.0088 0.447715 13.0088 1V21.17L18.2588 15.17C18.4895 14.8837 18.8586 14.747 19.2201 14.8141C19.5816 14.8812 19.8771 15.1412 19.9896 15.4913C20.1022 15.8413 20.0135 16.2248 19.7588 16.49L13.6388 23.49C13.361 23.8082 12.9612 23.9936 12.5388 24ZM9.18882 20.58C9.60252 20.2152 9.64279 19.5844 9.27882 19.17L5.77882 15.17C5.54819 14.8837 5.17903 14.747 4.81753 14.8141C4.45603 14.8812 4.16051 15.1412 4.048 15.4913C3.93548 15.8413 4.02412 16.2248 4.27882 16.49L7.77882 20.49C8.14358 20.9037 8.77442 20.944 9.18882 20.58Z"
      fill="#000028"
    />
  </symbol>

  <symbol id="icon-exp-arrow-up" fill="none" viewBox="0 0 24 25">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0288 24.0037C11.4765 24.0037 11.0288 23.556 11.0288 23.0037V2.83368L5.77882 8.83368C5.54819 9.12002 5.17903 9.25671 4.81753 9.18961C4.45603 9.12251 4.16051 8.86246 4.048 8.51242C3.93548 8.16239 4.02412 7.77885 4.27882 7.51368L10.3988 0.513681C10.8116 0.0407598 11.4752 -0.125101 12.062 0.0979996C12.6487 0.3211 13.0345 0.88597 13.0288 1.51368V23.0137C13.0233 23.5621 12.5772 24.0037 12.0288 24.0037ZM19.6888 8.92368C20.1025 8.55892 20.1428 7.92808 19.7788 7.51368L16.2788 3.51368C16.0482 3.22734 15.679 3.09065 15.3175 3.15775C14.956 3.22485 14.6605 3.48491 14.548 3.83494C14.4355 4.18497 14.5241 4.56852 14.7788 4.83368L18.2788 8.83368C18.6436 9.24739 19.2744 9.28765 19.6888 8.92368Z"
      fill="#000028"
    />
  </symbol>

  <symbol id="icon-exp-basket" viewBox="0 0 18 24">
    <path
      d="M12.19 24H5.86a3 3 0 0 1-3-2.78L2.07 10A2.09 2.09 0 0 1 0 8V6.5A2.5 2.5 0 0 1 2.5 4h13A2.5 2.5 0 0 1 18 6.5v1a2.5 2.5 0 0 1-2.5 2.5H7a1 1 0 0 1 0-2h8.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0-.5.5V8a2.11 2.11 0 0 1 2.07 1.89l.8 11.18a1 1 0 0 0 1 .93h6.33a1 1 0 0 0 1-.9l.8-8.24a1 1 0 0 1 2 .2l-.82 8.24a3 3 0 0 1-2.99 2.7zM13 1a1 1 0 0 0-1-1H6a1 1 0 0 0 0 2h6a1 1 0 0 0 1-1z"
    />
  </symbol>

  <symbol id="icon-exp-comment" viewBox="0 0 22.83 23.56">
    <path
      d="M1 23.56a1 1 0 0 1-1-1l.08-3.35a1 1 0 0 1 1-1 1 1 0 0 1 1 1v1.2L5.12 18 2 16.32a2 2 0 0 1-.83-2.7L7.29 2.13a4 4 0 1 1 7.07 3.74l-4.68 8.84a1 1 0 1 1-1.77-.94l4.68-8.84a2 2 0 0 0-3.53-1.86L3 14.55l3.08 1.63a2 2 0 0 1 .32 3.32l-4.77 3.84a1 1 0 0 1-.63.22zm21.83-1a1 1 0 0 0-1-1h-15a1 1 0 1 0 0 2h15a1 1 0 0 0 1-1z"
    />
  </symbol>

  <symbol id="icon-exp-cross" viewBox="0 0 24 24">
    <rect
      x="6.34277"
      y="7.75781"
      width="2"
      height="14"
      rx="1"
      transform="rotate(-45 6.34277 7.75781)"
      fill="currentColor"
    />
    <rect
      x="16.2432"
      y="6.34375"
      width="2"
      height="14"
      rx="1"
      transform="rotate(45 16.2432 6.34375)"
      fill="currentColor"
    />
  </symbol>

  <symbol id="icon-exp-plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.01 24">
    <path
      d="M10 13H7a1 1 0 0 1 0-2h3a1 1 0 1 1 0 2zm8.71 9a1 1 0 0 0-1.12-1.66 10.13 10.13 0 1 1 2.85-2.95 1 1 0 1 0 1.69 1.07A12 12 0 1 0 18.71 22zM18 12a1 1 0 0 0-1-1h-3a1 1 0 0 0 0 2h3a1 1 0 0 0 1-1zm-5 5v-3a1 1 0 1 0-2 0v3a1 1 0 0 0 2 0zm0-7V7a1 1 0 1 0-2 0v3a1 1 0 0 0 2 0z"
    />
  </symbol>

  <symbol id="icon-exp-plus" fill="none" viewBox="0 0 49 49">
    <rect width="2.84" height="19.87" x="22.62" y="14.15" fill="currentColor" rx="1.42" />
    <rect
      width="2.84"
      height="19.87"
      x="33.98"
      y="22.66"
      fill="currentColor"
      rx="1.42"
      transform="rotate(90 33.98 22.66)"
    />
  </symbol>

  <symbol id="icon-exp-upload" fill="none" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M18.99 18.98h-3a1 1 0 010-2h3a3 3 0 00.68-5.91 1 1 0 01-.76-1.13A6 6 0 007.24 7.28a1 1 0 01-.92.71 4.5 4.5 0 00.17 9h1.5a1 1 0 110 2h-1.5a6.5 6.5 0 01-.94-12.93 8 8 0 0115.44 2.92v.42a5 5 0 01-2 9.58zm-6 3v-11a1 1 0 00-1.6-.8l-4 3a1 1 0 101.2 1.6l2.4-1.8v9a1 1 0 002 0zm3.8-7.4a1 1 0 00-.2-1.4l-1-.75a1 1 0 10-1.2 1.6l1 .75a1 1 0 001.4-.2z"
      clip-rule="evenodd"
    />
  </symbol>

  <symbol id="icon-exp-information" viewBox="0 0 25 25">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M3.53 24a1 1 0 01-.63-1.78l1.76-1.43a2 2 0 012.29-.16 10 10 0 10-3.4-3.3 1 1 0 01-1.7 1.08 12 12 0 114.08 3.95l-1.77 1.4a1 1 0 01-.63.24zm9.46-8v-5a1 1 0 10-2 0v5a1 1 0 102 0zm-2-8a1 1 0 102 0 1 1 0 00-2 0z"
      clip-rule="evenodd"
    />
  </symbol>

  <!-- Others icon set -->
  <symbol id="icon-book" fill="none" viewBox="0 0 29 24">
    <path
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-width="2"
      d="M1 15.5V2.1c.01-.5.4-.93.9-.97C2.94 1.04 3.97 1 5.01 1c4.22 0 7.76.65 8.74 1.52a.38.38 0 00.53 0C15.25 1.65 18.79 1 23.01 1c1.04 0 2.07.04 3.1.13.51.04.9.47.9.98v17.6c0 .6-.52 1.06-1.1 1.02-.97-.08-1.93-.11-2.9-.11-4.22 0-7.76.65-8.74 1.52a.38.38 0 01-.53 0c-1-.87-4.51-1.52-8.74-1.52-.97 0-1.93.03-2.9.1-.58.05-1.1-.4-1.1-1V15.5zM14.01 3v19"
    />
  </symbol>

  <symbol id="icon-price" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="currentColor"
      d="M11.0574 23.1487H10.9874C10.5873 23.1207 10.2426 22.8563 10.1119 22.4772C9.98115 22.098 10.0896 21.6774 10.3874 21.4087L21.0174 10.7787C21.2228 10.5732 21.3286 10.2885 21.3074 9.99874L20.7374 2.57874L13.3174 2.00874C13.0276 1.98749 12.7429 2.09334 12.5374 2.29874L3.28736 11.5487C2.89964 11.9388 2.89964 12.5687 3.28736 12.9587L8.87736 18.5487C9.21714 18.9455 9.19429 19.5369 8.82492 19.9063C8.45555 20.2757 7.86412 20.2985 7.46736 19.9587L1.87736 14.3687C0.707547 13.1975 0.707547 11.3 1.87736 10.1287L11.1274 0.878735C11.7463 0.259801 12.6046 -0.0579715 13.4774 0.00873538L21.7574 0.648735C22.2489 0.686709 22.6394 1.07722 22.6774 1.56874L23.3174 9.84874C23.3841 10.7215 23.0663 11.5798 22.4474 12.1987L11.7874 22.8587C11.5938 23.0509 11.3301 23.1557 11.0574 23.1487ZM18.1274 9.41874C19.1388 8.40603 19.294 6.81984 18.498 5.63031C17.702 4.44078 16.1765 3.97923 14.8546 4.52796C13.5327 5.07669 12.7825 6.48284 13.0628 7.88641C13.3432 9.28997 14.5761 10.3 16.0074 10.2987C16.8131 10.3061 17.588 9.98891 18.1574 9.41874H18.1274ZM16.7174 6.58874C17.056 6.92459 17.1103 7.45281 16.8471 7.85054C16.5839 8.24827 16.0765 8.40481 15.635 8.22447C15.1935 8.04414 14.9408 7.57713 15.0313 7.10888C15.1219 6.64064 15.5305 6.30149 16.0074 6.29874C16.2732 6.2972 16.5286 6.40155 16.7174 6.58874Z"
    />
  </symbol>

  <symbol id="icon-internet" fill="none" viewBox="0 0 24.01 18">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M11.87 18a9.31 9.31 0 0 1-6.45-2.56A1 1 0 0 1 6.8 14a7.32 7.32 0 0 0 5.07 2 7.52 7.52 0 0 0 2.08-.29 1 1 0 0 1 .55 1.92 9.52 9.52 0 0 1-2.63.37zM21 10.29A8.84 8.84 0 0 0 21.11 9a9.13 9.13 0 0 0-9.24-9 9.31 9.31 0 0 0-8.34 5.13A1 1 0 0 0 5.32 6a7.29 7.29 0 0 1 6.54-4 7.13 7.13 0 0 1 7.24 7 6.81 6.81 0 0 1-.1 1 1 1 0 0 0 .84 1.14H20a1 1 0 0 0 1-.85zm2.92 1.38a2.19 2.19 0 0 0 .06-.76 1 1 0 0 0-1.07-.91 1 1 0 0 0-.91 1.15c-.2.75-4.08 1.64-10.68 0-5-1.26-8.42-3.26-9.2-4.59A.65.65 0 0 1 2 6.14a1 1 0 0 0-1.93-.52 2.48 2.48 0 0 0 .34 1.93c1.21 2.05 5.3 4.22 10.43 5.51a31.24 31.24 0 0 0 7.33 1c2.98.02 5.3-.66 5.76-2.39z"
    />
  </symbol>

  <symbol id="icon-lamp" fill="none" viewBox="0 0 14.45 24">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M9 18a1 1 0 0 1 0-2 .78.78 0 0 0 .78-.78v-.16a7.25 7.25 0 0 1 1.51-4.56 5.23 5.23 0 0 0-5.12-8.4 5.22 5.22 0 0 0-3.08 8.31 7.64 7.64 0 0 1 1.58 4.65v.16a.78.78 0 0 0 .78.78 1 1 0 0 1 0 2 2.78 2.78 0 0 1-2.78-2.78v-.16a5.63 5.63 0 0 0-1.17-3.43 7.22 7.22 0 1 1 11.34.13 5.26 5.26 0 0 0-1.06 3.3v.16A2.78 2.78 0 0 1 9 18zm1.22 2a1 1 0 0 0-1-1h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 1-1zm-1 3a1 1 0 0 0-1-1h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1z"
    />
  </symbol>

  <symbol id="icon-loading" fill="none" viewBox="0 0 18.09 22.75">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M17.8 18.29a1 1 0 0 1 0 1.41 10.43 10.43 0 1 1-1.87-16.22l-.18-2.41a1 1 0 0 1 2-.15l.34 4.66a1 1 0 0 1-1.7.78 8.43 8.43 0 1 0 0 11.92 1 1 0 0 1 1.41.01zm-4.4-9.75l1.47-.16a1 1 0 0 0 .88-1.1 1 1 0 0 0-1.1-.88l-1.47.16a1 1 0 0 0 .11 2z"
    />
  </symbol>

  <symbol id="icon-delivery" fill="none" viewBox="0 0 23.97 22.05">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M8.1 15.36a1 1 0 0 1-.94-.65L2.5 2.17.89 2a1 1 0 0 1 .21-2l1.62.18a2 2 0 0 1 1.66 1.29L9 14a1 1 0 0 1-.94 1.35zm2 6.68A3.05 3.05 0 0 0 12.88 19l10.43-3.88a1 1 0 0 0-.7-1.87l-10.43 3.86A2 2 0 0 0 10.84 19a1 1 0 0 1-.93 1 1 1 0 0 1-.9-.44 1 1 0 0 1 .48-1.49 1 1 0 0 0-.7-1.87 3 3 0 0 0 1 5.82zm4.3-7.89l3.89-1.45a1 1 0 0 0-.7-1.87l-3.89 1.45a1 1 0 0 1-1.29-.59L10.7 7a1 1 0 0 1 .59-1.29l5.62-2.09a1 1 0 0 1 1.29.59l2.44 6.56a1 1 0 0 0 1.88-.7l-2.45-6.56a3 3 0 0 0-3.86-1.77l-5.62 2.1a3 3 0 0 0-1.77 3.85l1.74 4.69a3 3 0 0 0 3.86 1.77z"
    />
  </symbol>

  <symbol id="icon-document" fill="none" viewBox="0 0 18 24">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M15 24H5a1 1 0 0 1 0-2h10a1 1 0 0 0 1-1V6.83a1 1 0 0 0-.29-.71l-3.83-3.83a1 1 0 0 0-.71-.29H3a1 1 0 0 0-1 1v20a1 1 0 0 1-2 0V3a3 3 0 0 1 3-3h8.17a3 3 0 0 1 2.12.88l3.83 3.83A3 3 0 0 1 18 6.83V21a3 3 0 0 1-3 3z"
    />
  </symbol>

  <symbol id="icon-settings" fill="none" viewBox="0 0 22.07 24">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M11.51 24h-1a3 3 0 0 1-2.88-2.19 1 1 0 0 1 1.93-.54 1 1 0 0 0 1 .73h1a1 1 0 0 0 1-.73l.46-1.67a2 2 0 0 1 .98-1.29c.16-.07.32-.16.48-.25s.31-.19.46-.29a2 2 0 0 1 1.66-.32l1.69.43a1 1 0 0 0 1.11-.47l.48-.83a1 1 0 0 0-.15-1.2l-1.22-1.23a2 2 0 0 1-.51-1.58 7.17 7.17 0 0 0 0-1.13 2 2 0 0 1 .56-1.57l1.22-1.24a1 1 0 0 0 .15-1.2l-.48-.83a1 1 0 0 0-1.12-.47l-1.69.43A2 2 0 0 1 15 6.25a7 7 0 0 0-1-.56 2 2 0 0 1-1-1.27l-.47-1.69a1 1 0 0 0-1-.73h-1a1 1 0 0 0-1 .73l-.4 1.67A2 2 0 0 1 8 5.69c-.16.08-.33.16-.48.25s-.31.19-.46.29a2 2 0 0 1-1.66.32l-1.67-.43a1 1 0 0 0-1.11.47l-.48.83a1 1 0 0 0 .15 1.2l1.22 1.23a2 2 0 0 1 .56 1.58 6.94 6.94 0 0 0 0 1.13 2 2 0 0 1-.56 1.57l-1.23 1.25a1 1 0 0 0-.15 1.2l.48.83a1 1 0 0 0 1.12.47 1 1 0 0 1 .5 1.94 3 3 0 0 1-3.35-1.41l-.48-.84A3 3 0 0 1 .86 14l1.22-1.24a8.15 8.15 0 0 1 0-1.46L.86 10A3 3 0 0 1 .4 6.41l.48-.83a3 3 0 0 1 3.34-1.4l1.69.43a7.31 7.31 0 0 1 .63-.41q.31-.18.64-.33l.49-1.67A3 3 0 0 1 10.56 0h1a3 3 0 0 1 2.88 2.19l.47 1.69a8.27 8.27 0 0 1 1.28.72l1.69-.42a3 3 0 0 1 3.34 1.41l.48.83a3 3 0 0 1-.46 3.6L20 11.27a8.31 8.31 0 0 1 0 1.46L21.21 14a3 3 0 0 1 .46 3.61l-.48.83a3 3 0 0 1-3.34 1.4l-1.69-.43a7.31 7.31 0 0 1-.63.41c-.21.12-.42.23-.64.33l-.48 1.67a3 3 0 0 1-2.9 2.18zM16 12a5 5 0 1 0-5 5 5 5 0 0 0 5-5zm-2 0a3 3 0 1 1-3-3 3 3 0 0 1 3 3z"
    />
  </symbol>

  <symbol id="icon-pencil" fill="none" viewBox="0 0 16 16">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.6791 7.11908L8.88244 5.32241L10.1811 4.02308L11.9771 5.81908L10.6791 7.11908ZM6.0531 11.7497L4.06843 11.9304L4.24443 9.95974L7.9891 6.21508L9.78643 8.01241L6.0531 11.7497ZM12.9358 4.89174L12.9351 4.89108L11.1098 3.06574C10.6158 2.57308 9.7671 2.54974 9.2991 3.01974L3.30177 9.01708C3.08443 9.23374 2.94977 9.52174 2.92177 9.82641L2.6691 12.6064C2.65177 12.8031 2.72177 12.9977 2.86177 13.1377C2.98777 13.2637 3.15777 13.3331 3.3331 13.3331C3.35377 13.3331 3.37377 13.3324 3.39377 13.3304L6.17377 13.0777C6.4791 13.0497 6.76643 12.9157 6.9831 12.6991L12.9811 6.70108C13.4664 6.21441 13.4458 5.40241 12.9358 4.89174Z"
      fill="#222B45"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="12" height="12">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6791 7.11908L8.88244 5.32241L10.1811 4.02308L11.9771 5.81908L10.6791 7.11908ZM6.0531 11.7497L4.06843 11.9304L4.24443 9.95974L7.9891 6.21508L9.78643 8.01241L6.0531 11.7497ZM12.9358 4.89174L12.9351 4.89108L11.1098 3.06574C10.6158 2.57308 9.7671 2.54974 9.2991 3.01974L3.30177 9.01708C3.08443 9.23374 2.94977 9.52174 2.92177 9.82641L2.6691 12.6064C2.65177 12.8031 2.72177 12.9977 2.86177 13.1377C2.98777 13.2637 3.15777 13.3331 3.3331 13.3331C3.35377 13.3331 3.37377 13.3324 3.39377 13.3304L6.17377 13.0777C6.4791 13.0497 6.76643 12.9157 6.9831 12.6991L12.9811 6.70108C13.4664 6.21441 13.4458 5.40241 12.9358 4.89174Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)" />
  </symbol>

  <symbol id="icon-suggestii" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="currentColor"
      d="M48 24C48 37.2548 37.2548 48 24 48C20.3784 48 16.9441 47.1978 13.8652 45.7614L10.588 47.9252C9.21268 48.8333 7.3909 47.7815 7.48965 46.1363L7.75778 41.6691C2.9886 37.2828 0 30.9904 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24Z"
    />
    <path
      fill-rule="evenodd"
      d="M17.8269 17.4659C17.2764 16.966 17 16.3482 17 15.6079C17 14.8676 17.274 14.2475 17.8269 13.7499C18.3773 13.25 19.1008 13 19.9949 13C20.8891 13 21.6126 13.238 22.163 13.7091C22.7134 14.1826 22.9899 14.7883 22.9899 15.5286C22.9899 16.3026 22.7134 16.9443 22.163 17.4515C21.6126 17.9587 20.8891 18.2135 19.9949 18.2135C19.1008 18.2159 18.3773 17.9659 17.8269 17.4659ZM17.5432 19.7638H22.449V33.7577H17.5432V19.7638Z"
      fill="#5750FB"
    />
    <path
      fill-rule="evenodd"
      d="M25.9079 17.4659C25.3575 16.966 25.0811 16.3482 25.0811 15.6079C25.0811 14.8676 25.3551 14.2475 25.9079 13.7499C26.4583 13.25 27.1818 13 28.076 13C28.9701 13 29.6936 13.238 30.244 13.7091C30.7945 14.1826 31.0709 14.7883 31.0709 15.5286C31.0709 16.3026 30.7945 16.9443 30.244 17.4515C29.6936 17.9587 28.9701 18.2135 28.076 18.2135C27.1818 18.2159 26.4583 17.9659 25.9079 17.4659ZM25.6243 19.7638H30.5301V33.7577H25.6243V19.7638Z"
      fill="#5750FB"
    />
  </symbol>

  <symbol id="icon-filled-document" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 23V3C4 1.89543 4.89543 1 6 1H18C19.1046 1 20 1.89543 20 3V21C20 22.1046 19.1046 23 18 23H8"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <rect x="7" y="5" width="5" height="2" rx="1" fill="currentColor" />
    <rect x="7" y="9" width="10" height="2" rx="1" fill="currentColor" />
    <rect x="7" y="13" width="10" height="2" rx="1" fill="currentColor" />
    <rect x="7" y="17" width="10" height="2" rx="1" fill="currentColor" />
  </symbol>

  <symbol id="icon-clock" viewBox="0 0 24 24">
    <path
      d="M12 24a12.05 12.05 0 1 1 10.12-5.59 1 1 0 1 1-1.69-1.07 10 10 0 1 0-2.85 2.95A1 1 0 1 1 18.71 22 12 12 0 0 1 12 24zm3.7-8.3a1 1 0 0 0 0-1.41l-2.7-2.7V6a1 1 0 0 0-2 0v6.41l3.29 3.29a1 1 0 0 0 1.41 0z"
    />
  </symbol>
  <symbol id="icon-left" viewBox="0 0 24 16">
    <path
      d="M8.17 16a1 1 0 0 1-.66-.25l-7-6.12A1.5 1.5 0 0 1 1.5 7H23a1 1 0 0 1 0 2H2.83l6 5.25A1 1 0 0 1 8.17 16zM4.83 5.25l4-3.5A1 1 0 0 0 7.51.25l-4 3.5a1 1 0 0 0 1.32 1.5z"
    />
  </symbol>
  <symbol id="icon-view" viewBox="0 0 24 14.39">
    <path
      d="M12 14.39A14.44 14.44 0 0 1 .18 7.78 1 1 0 0 1 0 7.21a1.09 1.09 0 0 1 .18-.6A14.44 14.44 0 0 1 12 0a14.44 14.44 0 0 1 11.82 6.61 1 1 0 1 1-1.64 1.14A12.47 12.47 0 0 0 12 2a12.33 12.33 0 0 0-9.77 5.2A12.33 12.33 0 0 0 12 12.39a11.65 11.65 0 0 0 7.37-2.7 1 1 0 1 1 1.26 1.55A13.63 13.63 0 0 1 12 14.39zM15 7a3 3 0 0 0-3-3 1 1 0 0 0 0 2 1 1 0 1 1-1 1 1 1 0 0 0-2 0 3 3 0 0 0 6 0z"
    />
  </symbol>

  <symbol id="icon-reload" viewBox="0 0 18.09 22.75">
    <path
      d="M17.8 18.29a1 1 0 0 1 0 1.41 10.43 10.43 0 1 1-1.87-16.22l-.18-2.41a1 1 0 0 1 2-.15l.34 4.66a1 1 0 0 1-1.7.78 8.43 8.43 0 1 0 0 11.92 1 1 0 0 1 1.41.01zm-4.4-9.75l1.47-.16a1 1 0 0 0 .88-1.1 1 1 0 0 0-1.1-.88l-1.47.16a1 1 0 0 0 .11 2z"
    />
  </symbol>

  <symbol id="icon-delete" viewBox="0 0 18 24">
    <path
      d="M12.19 24H5.86a3 3 0 0 1-3-2.78L2.07 10A2.09 2.09 0 0 1 0 8V6.5A2.5 2.5 0 0 1 2.5 4h13A2.5 2.5 0 0 1 18 6.5v1a2.5 2.5 0 0 1-2.5 2.5H7a1 1 0 0 1 0-2h8.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0-.5.5V8a2.11 2.11 0 0 1 2.07 1.89l.8 11.18a1 1 0 0 0 1 .93h6.33a1 1 0 0 0 1-.9l.8-8.24a1 1 0 0 1 2 .2l-.82 8.24a3 3 0 0 1-2.99 2.7zM13 1a1 1 0 0 0-1-1H6a1 1 0 0 0 0 2h6a1 1 0 0 0 1-1z"
    />
  </symbol>

  <symbol id="icon-restore" viewBox="0 0 24 24">
    <path
      d="M3.18596 19.2979C2.79824 19.6879 2.79824 20.3178 3.18596 20.7079C7.03815 24.569 13.2147 24.8103 17.3563 21.2613C21.4979 17.7124 22.2059 11.5717 18.9805 7.17346C15.7551 2.77517 9.68571 1.60457 5.05596 4.48786L5.23596 2.07786C5.27738 1.52557 4.86324 1.04428 4.31096 1.00286C3.75867 0.961434 3.27738 1.37557 3.23596 1.92786L2.89596 6.58786C2.86763 7.00256 3.09881 7.39155 3.47661 7.56489C3.85441 7.73824 4.30006 7.65979 4.59596 7.36786C7.00672 4.95636 10.6328 4.23465 13.7832 5.5393C16.9336 6.84396 18.9878 9.918 18.9878 13.3279C18.9878 16.7377 16.9336 19.8118 13.7832 21.1164C10.6328 22.4211 7.00672 21.6994 4.59596 19.2879C4.20318 18.9029 3.57324 18.9074 3.18596 19.2979ZM7.58596 9.54786L6.11596 9.38786C5.57032 9.32507 5.17743 8.83397 5.23596 8.28786C5.29874 7.74221 5.78984 7.34933 6.33596 7.40786L7.80596 7.56786C8.35824 7.59823 8.78133 8.07057 8.75096 8.62286C8.72058 9.17514 8.24824 9.59823 7.69596 9.56786L7.58596 9.54786Z"
    />
  </symbol>

  <symbol id="icon-up" viewBox="0 0 24.04 25.8">
    <path
      d="M23.55 9.11L12.72.26A1.13 1.13 0 0 0 12 0a1 1 0 0 0-.67.26L.49 9.11A1.5 1.5 0 0 0 0 10.22v6.84a1.45 1.45 0 0 0 .82 1.27 1.5 1.5 0 0 0 1.54-.07l5.17-3.46a1 1 0 1 0-1.11-1.66l-4.4 3v-5.71L12 2.34l10 8.09v5.69l-4.4-3a1 1 0 0 0-1.6.75v9.9H8v-4.41a1 1 0 0 0-2 0v4.46a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-8l3.68 2.46a1.5 1.5 0 0 0 1.54.07 1.45 1.45 0 0 0 .78-1.29v-6.84a1.5 1.5 0 0 0-.45-1.11z"
    />
  </symbol>
  <svg id="icon-publish" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 5.99707L24 17.9971C24 19.6539 22.6569 20.9971 21 20.9971H20C19.4477 20.9971 19 20.5494 19 19.9971C19 19.4448 19.4477 18.9971 20 18.9971H21C21.5523 18.9971 22 18.5494 22 17.9971L22 5.99707C22 5.44479 21.5523 4.99707 21 4.99707L3 4.99707C2.44772 4.99707 2 5.44479 2 5.99707L2 17.9971C2 18.5494 2.44772 18.9971 3 18.9971H4C4.55228 18.9971 5 19.4448 5 19.9971C5 20.5494 4.55228 20.9971 4 20.9971H3C1.34315 20.9971 0 19.6539 0 17.9971L0 5.99707C0 4.34022 1.34315 2.99707 3 2.99707L21 2.99707C22.6569 2.99707 24 4.34022 24 5.99707ZM12.45 11.1071C12.1247 10.9554 11.743 10.9898 11.45 11.1971L7.45 14.1971C7.00817 14.5284 6.91863 15.1552 7.25 15.5971C7.58137 16.0389 8.20817 16.1284 8.65 15.7971L11 13.9971L11 22.9971C11 23.5494 11.4477 23.9971 12 23.9971C12.5523 23.9971 13 23.5494 13 22.9971L13 11.9971C12.9989 11.6205 12.7863 11.2765 12.45 11.1071ZM16.6 14.1971L15.6 13.4471C15.1582 13.1157 14.5314 13.2052 14.2 13.6471C13.8686 14.0889 13.9582 14.7157 14.4 15.0471L15.4 15.7971C15.6858 16.0114 16.0643 16.057 16.3928 15.9167C16.7214 15.7763 16.9501 15.4714 16.9928 15.1167C17.0356 14.762 16.8858 14.4114 16.6 14.1971Z"
    />
    <circle cx="4" cy="7" r="1" />
    <circle cx="7" cy="7" r="1" />
    <circle cx="10" cy="7" r="1" />
  </svg>

  <svg id="icon-logout" viewBox="0 0 24 24">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 24H5C3.34315 24 2 22.6569 2 21V3C2 1.34315 3.34315 0 5 0H17C18.6569 0 20 1.34315 20 3V4C20 4.55228 19.5523 5 19 5C18.4477 5 18 4.55228 18 4V3C18 2.44772 17.5523 2 17 2H5C4.44772 2 4 2.44772 4 3V21C4 21.5523 4.44772 22 5 22H17C17.5523 22 18 21.5523 18 21V20C18 19.4477 18.4477 19 19 19C19.5523 19 20 19.4477 20 20V21C20 22.6569 18.6569 24 17 24ZM22.89 12.45C23.0416 12.1247 23.0073 11.743 22.8 11.45L19.8 7.45C19.4686 7.00817 18.8418 6.91863 18.4 7.25C17.9582 7.58137 17.8686 8.20817 18.2 8.65L20 11H11C10.4477 11 10 11.4477 10 12C10 12.5523 10.4477 13 11 13H22C22.3766 12.9989 22.7206 12.7863 22.89 12.45ZM19.8 16.6L20.55 15.6C20.8814 15.1582 20.7918 14.5314 20.35 14.2C19.9082 13.8686 19.2814 13.9582 18.95 14.4L18.2 15.4C17.8686 15.8418 17.9582 16.4686 18.4 16.8C18.8418 17.1314 19.4686 17.0418 19.8 16.6Z"
    />
  </svg>

  <svg id="icon-right" viewBox="0 0 24 16">
    <path
      d="M22.5 9H1a1 1 0 1 1 0-2h20.17l-6-5.25a1 1 0 0 1 1.32-1.5l7 6.12A1.5 1.5 0 0 1 22.5 9zm-.33-1.12zm-5.68 7.88l4-3.5a1 1 0 0 0-1.32-1.51l-4 3.5a1 1 0 0 0 1.32 1.51z"
    />
  </svg>

  <svg id="icon-filter" viewBox="0 0 18 24">
    <path
      d="M8 24a2 2 0 0 1-2-2v-9.33a1 1 0 0 0-.2-.6L.6 5.13a3 3 0 0 1-.6-1.8V1a1 1 0 0 1 2 0v2.33a1 1 0 0 0 .2.6l5.2 6.93a3 3 0 0 1 .6 1.8V22l2-1.5v-7.83a3 3 0 0 1 .6-1.8l5.2-6.93a1 1 0 0 0 .2-.6V3a1 1 0 0 0-1-1H5a1 1 0 0 1 0-2h10a3 3 0 0 1 3 3v.33a3 3 0 0 1-.6 1.8l-5.2 6.93a1 1 0 0 0-.2.6v7.84a2 2 0 0 1-.8 1.6l-2 1.5A2 2 0 0 1 8 24z"
    />
  </svg>

  <svg id="icon-sort-ascending" viewBox="0 0 24 24.01">
    <path
      d="M12 24a12 12 0 1 1 10.12-5.59 1 1 0 0 1-1.69-1.07 10 10 0 1 0-2.85 2.95A1 1 0 1 1 18.71 22 12 12 0 0 1 12 24zm2.5-16.11a1 1 0 0 0-1-1h-3a1 1 0 0 0 0 2h3a1 1 0 0 0 1-1zm1.5 4a1 1 0 0 0-1-1H9a1 1 0 0 0 0 2h6a1 1 0 0 0 1-1zm2 4a1 1 0 0 0-1-1H7a1 1 0 0 0 0 2h10a1 1 0 0 0 1-1z"
    />
  </svg>

  <svg id="icon-sort-descending" viewBox="0 0 24 24.01">
    <path
      d="M12 24a12 12 0 1 1 10.12-5.59 1 1 0 0 1-1.69-1.07 10 10 0 1 0-2.85 2.95A1 1 0 1 1 18.71 22 12 12 0 0 1 12 24zm2.49-8.12a1 1 0 0 0-1-1h-3a1 1 0 0 0 0 2h3a1 1 0 0 0 1.01-.99zm1.5-4a1 1 0 0 0-1-1H9a1 1 0 0 0 0 2h6a1 1 0 0 0 1-.99zm2-4a1 1 0 0 0-1-1H7a1 1 0 0 0 0 2h10a1 1 0 0 0 1-.99z"
    />
  </svg>

  <svg id="icon-arrow-down" viewBox="0 0 24 16.57">
    <path
      d="M12 16.57a1 1 0 0 1-.67-.26L.49 6.45A1.5 1.5 0 0 1 0 5.34V2.42A1.5 1.5 0 0 1 2.23 1.1l8.26 4.59a1 1 0 0 1-1 1.75L2 3.27v1.85l10 9.09 10-9.09V2.43l-7.45 5a1 1 0 1 1-1.11-1.66L21.67.25A1.5 1.5 0 0 1 24 1.5v3.84a1.5 1.5 0 0 1-.49 1.11l-10.84 9.86a1 1 0 0 1-.67.26z"
    />
  </svg>

  <svg id="icon-arrow-up" viewBox="0 0 24 16.57">
    <path
      d="M1.5 16.57A1.5 1.5 0 0 1 0 15.06v-3.84a1.51 1.51 0 0 1 .49-1.11L11.33.26a1 1 0 0 1 1.35 0l10.84 9.85a1.5 1.5 0 0 1 .48 1.11v2.93a1.5 1.5 0 0 1-2.23 1.31l-8.26-4.59a1 1 0 1 1 1-1.75L22 13.3v-1.86L12 2.35 2 11.44v2.69l7.45-5a1 1 0 0 1 1.11 1.66l-8.23 5.52a1.49 1.49 0 0 1-.83.26z"
    />
  </svg>

  <svg id="icon-check-circle" viewBox="0 0 22 22" fill="none">
    <circle cx="11" cy="11" r="11" fill="currentColor" />
    <path
      d="M7 10.9674L10.0227 14L16 8"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg id="icon-drag-handle" viewBox="0 0 11 18" fill="none">
    <rect width="4" height="4" rx="1" fill="currentColor" />
    <rect x="7" width="4" height="4" rx="1" fill="currentColor" />
    <rect y="7" width="4" height="4" rx="1" fill="currentColor" />
    <rect x="7" y="7" width="4" height="4" rx="1" fill="currentColor" />
    <rect y="14" width="4" height="4" rx="1" fill="currentColor" />
    <rect x="7" y="14" width="4" height="4" rx="1" fill="currentColor" />
  </svg>
</svg>
