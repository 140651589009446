<ng-select
  class="autocomplete"
  [formControl]="_control"
  [items]="searchResult$ | async | slugStringify"
  [typeahead]="searchInput$"
  [placeholder]="multiple ? 'Écoles' : 'École'"
  [virtualScroll]="true"
  [multiple]="multiple"
  [closeOnSelect]="!multiple"
  [loading]="loading"
  [required]="true"
  [clearSearchOnAdd]="true"
  [hideSelected]="true"
  appearance="outline"
  dropdownPosition="auto"
  typeToSearchText="Rechercher une école..."
  notFoundText="Aucun résultat"
  clearAllText="Effacer"
/>
