import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { JwtSet, JwtState } from '@wizbii-utils/angular/stores';
import { AuthenticationWebservice } from '@wizbii-utils/angular/webservices';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginTokenGuard {
  constructor(
    readonly store: Store,
    readonly authenticationWebservice: AuthenticationWebservice,
    readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const isLogged$: Observable<boolean> = this.store.select(JwtState.isLogged);
    const loginToken$: Observable<string | null> = of(route.queryParamMap.get('login-token'));

    return combineLatest([isLogged$, loginToken$]).pipe(
      switchMap(([isLogged, loginToken]) => {
        if (isLogged) {
          return loginToken
            ? this.router
                .navigate([], {
                  queryParams: { ...route.queryParams, 'login-token': undefined },
                  queryParamsHandling: 'merge',
                })
                .then(() => true)
            : of(true);
        }

        if (!loginToken) {
          return of(false);
        }

        return this.authenticationWebservice.getJwtFromLoginToken(loginToken).pipe(
          tap((tokens) => {
            this.store.dispatch(new JwtSet(tokens));
          }),
          map(() => true),
          catchError(() => {
            return of(false);
          })
        );
      })
    );
  }
}
