import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommitMessageDialogComponent } from '@core/components/dialogs/commit-message-dialog/commit-message-dialog.component';
import { DeleteConfirmationDialogComponent } from '@core/components/dialogs/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { PublishRevisionDialogComponent } from '@core/components/dialogs/publish-revision-dialog/publish-revision-dialog.component';
import { RestoreRevisionDialogComponent } from '@core/components/dialogs/restore-revision-dialog/restore-revision-dialog.component';
import { ResourceType } from '@models/commons/locales.enum';
import { CommitOptions } from '@models/commons/utils';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private readonly dialog: MatDialog) {}

  showCommitMessageDialog(): MatDialogRef<CommitMessageDialogComponent, CommitOptions> {
    return this.dialog.open(CommitMessageDialogComponent);
  }

  showRestoreRevisionDialog(): MatDialogRef<RestoreRevisionDialogComponent, boolean> {
    return this.dialog.open(RestoreRevisionDialogComponent);
  }

  showPublishRevisionDialog(type: ResourceType): MatDialogRef<PublishRevisionDialogComponent, boolean> {
    return this.dialog.open(PublishRevisionDialogComponent, { data: { type } });
  }

  showDeleteConfirmationDialog(type: ResourceType): MatDialogRef<DeleteConfirmationDialogComponent, boolean> {
    return this.dialog.open(DeleteConfirmationDialogComponent, { data: { type } });
  }
}
