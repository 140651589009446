<div class="dialog">
  <h4 class="dialog__title" mat-dialog-title>Publier {{ label }}</h4>

  <div class="dialog__description" mat-dialog-content>Vous allez mettre en ligne {{ label }}. Êtes-vous sûr(e) ?</div>

  <div class="dialog__actions" mat-dialog-actions>
    <button class="btn btn--outline" (click)="cancel()" type="button">Annuler</button>
    <button class="btn" (click)="approve()" type="button" cdkFocusInitial>Publier</button>
  </div>
</div>
