import { environment } from '@environment';
import { BugsnagGroupingRule } from '@wizbii/angular-bugsnag';

export const bugsnagConfig: {
  apiKey: string;
  appVersion: string;
  autoTrackSessions: boolean;
  isDeployed: boolean;
  releaseStage: string;
  groupingRules?: BugsnagGroupingRule[];
} = {
  releaseStage: environment.platform,
  appVersion: environment.version,
  apiKey: environment.bugsnagId,
  autoTrackSessions: false,
  isDeployed: ['staging', 'prod'].includes(environment.platform),
  groupingRules: [],
};
