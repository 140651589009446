import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';
import { AlgoliaService } from '@core/services/algolia/algolia.service';
import { environment } from '@environment';
import { ConstraintType } from '@models/constraint';
import { Observable, Subject, concat, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-school-guard',
  templateUrl: './school-guard.component.html',
  styleUrls: ['./school-guard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SchoolGuardComponent),
      multi: true,
    },
  ],
})
export class SchoolGuardComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() multiple: boolean;
  @Input() constraintType: ConstraintType;

  private readonly destroyed$ = new Subject<void>();

  loading = false;
  searchResult$: Observable<string[]>;
  searchInput$ = new Subject<string>();

  private _value: string | string[];

  set value(value: string | string[]) {
    if (this._value === value) {
      return;
    }
    this._value = value;
    this._control.setValue(value);
    this.onChanged(value);
    this.onTouched();
  }
  get value(): string | string[] {
    return this._value;
  }

  private _required: boolean;

  @Input() set required(isRequired: boolean) {
    this._control.setValidators(isRequired ? Validators.required : null);
    this._required = isRequired;
  }
  get required() {
    return this._required;
  }

  private _disabled: boolean;

  @HostBinding('attr.disabled') @Input() set disabled(isDisabled: boolean) {
    isDisabled ? this._control.disable() : this._control.enable();
    this._disabled = isDisabled;
  }
  get disabled() {
    return this._disabled;
  }

  _control = new UntypedFormControl();

  constructor(
    private readonly algoliaService: AlgoliaService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => (this.value = value));

    this.searchResult$ = concat(
      of([]),
      this.searchInput$.pipe(
        filter((term) => !!term),
        distinctUntilChanged(),
        tap(() => (this.loading = true)),
        switchMap((term: string) =>
          this.algoliaService.fetchIndex(environment.algolia.index.schools, term).pipe(
            catchError(() => of([])),
            map((res: any) => res.hits),
            map((i: { objectID: string }[]) => i.map((v) => v.objectID)),
            tap(() => (this.loading = false))
          )
        )
      )
    );
  }

  onChanged: (_: string | string[]) => void = (_: string | string[]) => {};
  onTouched: () => void = () => {};

  registerOnChange(fn: (value: string | string[]) => void): void {
    this.onChanged = (value) => {
      fn(value);
      this.cdr.markForCheck();
    };
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = () => {
      fn();
      this.cdr.markForCheck();
    };
  }
  setDisabledState = (isDisabled: boolean) => {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  };
  writeValue = (value: string | string[]) => (this.value = value);

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
