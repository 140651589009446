import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngxs/store';
import { JwtDelete, JwtSet, JwtState } from '@wizbii-utils/angular/stores';
import { AuthenticationWebservice } from '@wizbii-utils/angular/webservices';
import { JwtServiceInterface, JwtTokens } from '@wizbii/jwt';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class JwtService implements JwtServiceInterface {
  blacklistRoutes: (string | RegExp)[];

  constructor(
    private readonly store: Store,
    private readonly authService: AuthenticationWebservice
  ) {
    this.blacklistRoutes = [
      'https://storage.googleapis.com',
      new RegExp(`https://auth.${environment.apiDomain}(?!(/v1/user).*(/email|password|overview))`, 'i'),
    ];
  }

  getTokens(): Observable<JwtTokens> {
    return this.store.selectOnce(JwtState);
  }

  logout(): void {
    this.store.dispatch(new JwtDelete()).pipe(tap(() => window.location.reload()));
  }

  refreshToken(tokens: JwtTokens): Observable<JwtTokens> {
    return this.authService
      .refreshToken(tokens)
      .pipe(switchMap((newTokens) => this.store.dispatch(new JwtSet(newTokens)).pipe(map(() => newTokens))));
  }

  saveLocalTokens(jwtTokens: JwtTokens): void {
    this.store.dispatch(new JwtSet(jwtTokens));
  }
}
