import { Revision } from '@models/revision';
import { Statement, StatementTypeEnum } from '@models/statement';
import { QuestionType } from '@wizbii/models';

export enum TransformerTypeEnum {
  Age = 'age',
  Distance_in_km = 'distance_in_km',
  Continent_from_country = 'continent_from_country',
  Country_from_country = 'country_from_country',
  State_from_city = 'state_from_city',
  Country_from_city = 'country_from_city',
  Department_from_city = 'department_from_city',
  Continent_from_city = 'continent_from_city',
  City_from_city = 'city_from_city',
  Zone_from_city = 'zone_from_city',
  Sequence = 'sequence',
  Is_from_european_union = 'is_from_european_union',
}

export const LabelByTransformerType: Record<TransformerTypeEnum, string> = {
  [TransformerTypeEnum.State_from_city]: 'Ville en région',
  [TransformerTypeEnum.Age]: 'Âge',
  [TransformerTypeEnum.Department_from_city]: 'Ville en département',
  [TransformerTypeEnum.Distance_in_km]: 'Distance en km',
  [TransformerTypeEnum.City_from_city]: 'Ville en ville',
  [TransformerTypeEnum.Country_from_city]: 'Ville en pays',
  [TransformerTypeEnum.Continent_from_city]: 'Ville en continent',
  [TransformerTypeEnum.Continent_from_country]: 'Pays en continent',
  [TransformerTypeEnum.Country_from_country]: 'Pays en pays',
  [TransformerTypeEnum.Zone_from_city]: 'Ville en zone',
  [TransformerTypeEnum.Sequence]: 'Séquence',
  [TransformerTypeEnum.Is_from_european_union]: 'Pays est en Union Européenne',
};

export interface Transformer {
  id: string;
  transformerId: string;
  name: string;
  revision: Revision;
  type: TransformerTypeEnum;
  dataType?: DataTypeEnum;
  target: TargetTypeEnum;
}

export interface SequenceTransformer extends Transformer {
  sequenceResource: StatementResource;
}

export interface StatementResource {
  returnType: string;
  statementResources: Statement[];
}

export const LabelByStatementType: Record<StatementTypeEnum, string> = {
  [StatementTypeEnum.AssignVariable]: 'Assigner une variable',
  [StatementTypeEnum.IncrementVariable]: 'Incrémenter une variable',
  [StatementTypeEnum.DecrementVariable]: 'Décrémenter une variable',
  [StatementTypeEnum.Return]: 'Retour',
  [StatementTypeEnum.Conditional]: 'Condition',
  [StatementTypeEnum.AssignVariableUsingRanges]: 'Assigner une variable avec matrice',
};

export enum ExtraTransformerKeys {
  birthdayQuestionId = 'birthdayQuestionId',
  cityQuestionId = 'cityQuestionId',
  countryQuestionId = 'countryQuestionId',
  fromQuestionId = 'fromQuestionId',
  toQuestionId = 'toQuestionId',
  locationQuestionId = 'locationQuestionId',
}

export const QuestionTypeByExtraTransformerKeys: Record<ExtraTransformerKeys, QuestionType> = {
  [ExtraTransformerKeys.birthdayQuestionId]: QuestionType.Date,
  [ExtraTransformerKeys.cityQuestionId]: QuestionType.City,
  [ExtraTransformerKeys.countryQuestionId]: QuestionType.Country,
  [ExtraTransformerKeys.fromQuestionId]: QuestionType.City,
  [ExtraTransformerKeys.toQuestionId]: QuestionType.City,
  [ExtraTransformerKeys.locationQuestionId]: QuestionType.Country,
};

export const LabelByTransformerKey: Record<ExtraTransformerKeys, string> = {
  birthdayQuestionId: "Question (Date d'anniversaire)",
  fromQuestionId: 'Question (Provenance)',
  toQuestionId: 'Question (Destination)',
  cityQuestionId: 'Question (Ville)',
  countryQuestionId: 'Question (Pays)',
  locationQuestionId: 'Question (Pays)',
};

export const TransformerKeysByType: Record<TransformerTypeEnum, ExtraTransformerKeys[]> = {
  [TransformerTypeEnum.Age]: [ExtraTransformerKeys.birthdayQuestionId],
  [TransformerTypeEnum.Department_from_city]: [ExtraTransformerKeys.cityQuestionId],
  [TransformerTypeEnum.State_from_city]: [ExtraTransformerKeys.cityQuestionId],
  [TransformerTypeEnum.Distance_in_km]: [ExtraTransformerKeys.fromQuestionId, ExtraTransformerKeys.toQuestionId],
  [TransformerTypeEnum.City_from_city]: [ExtraTransformerKeys.cityQuestionId],
  [TransformerTypeEnum.Country_from_city]: [ExtraTransformerKeys.cityQuestionId],
  [TransformerTypeEnum.Continent_from_city]: [ExtraTransformerKeys.cityQuestionId],
  [TransformerTypeEnum.Continent_from_country]: [ExtraTransformerKeys.countryQuestionId],
  [TransformerTypeEnum.Country_from_country]: [ExtraTransformerKeys.countryQuestionId],
  [TransformerTypeEnum.Zone_from_city]: [ExtraTransformerKeys.cityQuestionId],
  [TransformerTypeEnum.Sequence]: [],
  [TransformerTypeEnum.Is_from_european_union]: [ExtraTransformerKeys.locationQuestionId],
};

export enum DataTypeEnum {
  Integer = 'integer',
  Float = 'float',
  Boolean = 'boolean',
  String = 'string',
}

export const LabelByDataType: Record<DataTypeEnum, string> = {
  integer: 'Integer',
  float: 'Float',
  boolean: 'Boolean',
  string: 'String',
};

export enum TargetTypeEnum {
  Suggestii = 'suggestii',
  Dropito = 'dropito',
}

export const LabelByTargetType: Record<TargetTypeEnum, string> = {
  [TargetTypeEnum.Suggestii]: 'Suggestii',
  [TargetTypeEnum.Dropito]: 'Dropito',
};
