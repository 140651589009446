import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  Input,
  OnInit,
  forwardRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';
import { ConstraintType } from '@models/constraint';
import { CityWebservice } from '@wizbii-utils/angular/webservices';
import { City } from '@wizbii/models';
import { Observable, Subject, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import slugify from 'slugify';

type ControlType = string | string[] | undefined;

@Component({
  selector: 'app-city-guard',
  templateUrl: './city-guard.component.html',
  styleUrls: ['./city-guard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CityGuardComponent),
      multi: true,
    },
  ],
})
export class CityGuardComponent implements ControlValueAccessor, OnInit {
  @Input() multiple!: boolean;
  @Input() constraintType!: ConstraintType;
  readonly #cityWebservice = inject(CityWebservice);
  loading = false;
  searchResult$!: Observable<City[]>;
  searchInput$ = new Subject<string>();

  readonly #destroyRef = inject(DestroyRef);

  private _value: ControlType;

  set value(value: ControlType) {
    const arrayEquals = (a: ControlType, b: ControlType) =>
      Array.isArray(a) &&
      Array.isArray(b) &&
      b.length === a.length &&
      a.reduce((acc, v) => acc || b.includes(v), false);

    if (arrayEquals(value, this._value) || this._value === value) {
      return;
    }

    this._value = value;
    this._control.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get value(): ControlType {
    return this._value;
  }

  private _required!: boolean;

  @Input() set required(isRequired: boolean) {
    this._control.setValidators(isRequired ? Validators.required : null);
    this._required = isRequired;
  }
  get required() {
    return this._required;
  }

  private _disabled!: boolean;

  @HostBinding('attr.disabled') @Input() set disabled(isDisabled: boolean) {
    isDisabled ? this._control.disable() : this._control.enable();
    this._disabled = isDisabled;
  }
  get disabled() {
    return this._disabled;
  }

  _control = new UntypedFormControl();

  private onChange = (_: any) => {};
  private onTouched = () => {};

  writeValue(obj: ControlType): void {
    this.value = obj;
  }
  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this._control.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe({
      next: (value) => {
        this.value = Array.isArray(value)
          ? value.map((item) => slugify(item.city || item, { lower: true }))
          : slugify(value.city || value, { lower: true });
      },
    });

    this.searchResult$ = concat(
      of([]),
      this.searchInput$.pipe(
        filter((term) => !!term),
        distinctUntilChanged(),
        debounceTime(150),
        tap(() => (this.loading = true)),
        switchMap((term) =>
          this.#cityWebservice.getBy({ query: term }).pipe(
            catchError(() => of([])),
            map((i: City[]) => i.filter((value) => !!value)),
            tap(() => (this.loading = false))
          )
        )
      )
    );
  }
}
