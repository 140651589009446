@if (constraintType && dependency && isComparable) {
  @if (currentGuard === GuardTypeEnum.Number) {
    <app-number-guard [formControl]="_control" />
  }
  @if (currentGuard === GuardTypeEnum.Text) {
    <app-text-guard [formControl]="_control" />
  }
  @if (currentGuard === GuardTypeEnum.Date) {
    <app-date-guard [formControl]="_control" />
  }
  @if (currentGuard === GuardTypeEnum.Select) {
    <app-select-guard
      [formControl]="_control"
      [isMultiple]="isMultiple"
      [constraintType]="constraintType"
      [dependencyType]="dependencyType"
    />
  }
  @if (currentGuard === GuardTypeEnum.Choice) {
    <app-choice-guard
      [formControl]="_control"
      [isMultiple]="isMultiple"
      [constraintType]="constraintType"
      [dependency]="dependency"
    />
  }
  @if (currentGuard === GuardTypeEnum.City) {
    <app-city-guard [formControl]="_control" [constraintType]="constraintType" [multiple]="isMultiple" />
  }
  @if (currentGuard === GuardTypeEnum.School) {
    <app-school-guard [formControl]="_control" [constraintType]="constraintType" [multiple]="isMultiple" />
  }
}
