<ng-select
  class="autocomplete"
  [formControl]="_control"
  [items]="searchResult$ | async"
  [typeahead]="searchInput$"
  [placeholder]="multiple ? 'Villes' : 'Ville'"
  [virtualScroll]="true"
  [multiple]="multiple"
  [closeOnSelect]="!multiple"
  [loading]="loading"
  [required]="true"
  [clearSearchOnAdd]="true"
  [hideSelected]="true"
  appearance="outline"
  dropdownPosition="auto"
  typeToSearchText="Rechercher une ville..."
  notFoundText="Aucun résultat"
  clearAllText="Effacer"
  appendTo="body"
>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    <span class="ng-value-label">
      @if (!item.city) {
        {{ item | slugStringify }}
      }
      @if (item.city) {
        {{ item.city }}, {{ item.department }}
      }
    </span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <span>{{ item.city }}, {{ item.department }}</span>
  </ng-template>
</ng-select>
