<div class="dialog">
  <h4 class="dialog__title">Supprimer {{ label }}</h4>

  <div class="dialog__description">Vous allez supprimer {{ label }} et toutes ses révisions. Êtes-vous sûr(e) ?</div>

  <div class="dialog__actions" mat-dialog-actions>
    <button class="btn btn--danger--outline btn--outline" (click)="cancel()" type="button">Annuler</button>
    <button class="btn btn--danger" (click)="approve()" type="button" cdkFocusInitial>Supprimer</button>
  </div>
</div>
