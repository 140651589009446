import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelByType, ResourceType } from '@models/commons/locales.enum';

@Component({
  selector: 'app-publish-revision-dialog',
  templateUrl: './publish-revision-dialog.component.html',
  styleUrls: ['./publish-revision-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishRevisionDialogComponent implements OnInit {
  label: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: ResourceType },

    public dialogRef: MatDialogRef<PublishRevisionDialogComponent, boolean>
  ) {}

  ngOnInit(): void {
    this.label = LabelByType[this.data.type] || '';
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  approve(): void {
    this.dialogRef.close(true);
  }
}
