import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { GeoRegion, ISOCountryEnum } from '@models/commons/geo-regions';
import { buildUrl } from '@wizbii-utils/angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LocationWebservice {
  private readonly baseUrl = `${environment.api.suggestion}/internal/locations`;

  constructor(private readonly http: HttpClient) {}

  private readonly convertNormalizedGeographicalRegionResponseToGeoRegions = (
    response: NormalizedGeographicalRegionResponse
  ): GeoRegion[] => {
    const geoRegions: GeoRegion[] = [];

    Object.keys(response).forEach((countryCode) => {
      response[countryCode].forEach((item: string) => {
        geoRegions.push({
          countryCode: countryCode as ISOCountryEnum,
          slug: item,
        });
      });
    });

    return geoRegions;
  };

  getDepartments(): Observable<GeoRegion[]> {
    const url = buildUrl(`${this.baseUrl}/departments`);
    return this.http.get<NormalizedGeographicalRegionResponse>(url).pipe(
      map((response) => {
        return this.convertNormalizedGeographicalRegionResponseToGeoRegions(response);
      })
    );
  }

  getContinents(): Observable<string[]> {
    const url = buildUrl(`${this.baseUrl}/continents`);
    return this.http.get<string[]>(url);
  }

  getCountries(): Observable<string[]> {
    const url = buildUrl(`${this.baseUrl}/countries`);
    return this.http.get<string[]>(url);
  }

  getStates(): Observable<GeoRegion[]> {
    const url = buildUrl(`${this.baseUrl}/states`);
    return this.http.get<NormalizedGeographicalRegionResponse>(url).pipe(
      map((response) => {
        return this.convertNormalizedGeographicalRegionResponseToGeoRegions(response);
      })
    );
  }

  searchCity(term: string): Observable<string[]> {
    const url = buildUrl(`${this.baseUrl}/cities`, { city: term });
    return this.http.get<string[]>(url);
  }
}
type NormalizedGeographicalRegionResponse = Record<string, string[]>;
