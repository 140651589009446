import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';
import { ConstraintType } from '@models/constraint';
import { PickableDependencyItem } from '@models/pickable-dependency-item';
import { ChoiceQuestion } from '@models/question';
import { SuggestionWebservice } from '@webservices/suggestion-api/suggestion.webservice';
import { LangEnum } from '@wizbii/models';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-choice-guard',
  templateUrl: './choice-guard.component.html',
  styleUrls: ['./choice-guard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChoiceGuardComponent),
      multi: true,
    },
  ],
})
export class ChoiceGuardComponent implements OnInit, OnDestroy {
  @Input() dependency!: Partial<PickableDependencyItem>;
  @Input() constraintType!: ConstraintType;
  @Input() isMultiple!: boolean;

  private readonly destroyed$ = new Subject<void>();

  question$!: Observable<ChoiceQuestion>;
  questionsByLang$!: Observable<[OptionsChoiceByLang[]]>;
  possibleValuesLangKey = LangEnum.fr;

  private _value!: string | string[];

  set value(value: string | string[]) {
    if (this._value === value) {
      return;
    }
    this._value = value;
    this._control.setValue(value);
    this.onChanged(value);
    this.onTouched();
  }

  get value(): string | string[] {
    return this._value;
  }

  private _required!: boolean;

  @Input() set required(isRequired: boolean) {
    this._control.setValidators(isRequired ? Validators.required : null);
    this._required = isRequired;
  }
  get required(): boolean {
    return this._required;
  }

  private _disabled!: boolean;

  @HostBinding('attr.disabled') @Input() set disabled(isDisabled: boolean) {
    isDisabled ? this._control.disable() : this._control.enable();
    this._disabled = isDisabled;
  }
  get disabled(): boolean {
    return this._disabled;
  }

  _control = new UntypedFormControl();

  constructor(
    readonly suggestionWebservice: SuggestionWebservice,
    private readonly cdr: ChangeDetectorRef
  ) {}

  onChanged: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChanged = (value) => {
      fn(value);
      this.cdr.markForCheck();
    };
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = () => {
      fn();
      this.cdr.markForCheck();
    };
  }

  ngOnInit(): void {
    this._control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => (this.value = value));

    this.question$ = this.suggestionWebservice.getQuestion(this.dependency.id) as Observable<ChoiceQuestion>;
    this.questionsByLang$ = this.question$.pipe(
      map((question: ChoiceQuestion) => {
        const data = question.possibleValues;
        const values: OptionsChoiceByLang[] = [];
        Object.keys(data).forEach((key: string) => {
          values.push(
            data[key].map((choice: Record<string, string | undefined>) => {
              return {
                id: choice.id,
                label: choice.label,
                value: choice.value,
                lang: key,
              };
            })
          );
        });
        return this.groupBy(values.flat(), 'id');
      })
    );
  }

  trackByValue(_: any, value: { id: string; label: string }): string {
    return value.id;
  }

  private groupBy(arr: any[], prop: string): any {
    const groupByMap = new Map(Array.from(arr, (obj) => [obj[prop], []]));
    arr.forEach((obj) => groupByMap.get(obj[prop]).push(obj));
    return Array.from(groupByMap.values());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  trackByOptionsChoiceLang(idx: number, _: OptionsChoiceByLang[]): number {
    return idx;
  }

  trackByOptionsChoiceByLang(_: number, item: OptionsChoiceByLang): string {
    return item.id;
  }
}

interface OptionsChoiceByLang {
  id: string;
  label: string;
  value?: string;
  lang: string;
}
