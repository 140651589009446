<div class="dialog">
  <h4 class="dialog__title">Restaurer une révision</h4>

  <div class="dialog__description">Vous allez revenir à une version antérieure. Êtes-vous sûr(e) ?</div>

  <div class="dialog__actions" mat-dialog-actions>
    <button class="btn btn--outline" (click)="cancel()" type="button">Annuler</button>
    <button class="btn" (click)="approve()" type="button" cdkFocusInitial>Restaurer</button>
  </div>
</div>
