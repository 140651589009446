import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';
import { GeoRegion, GeoRegionSelectOption } from '@models/commons/geo-regions';
import { ConstraintType, DependencyType } from '@models/constraint';
import { TransformerTypeEnum } from '@models/transformer';
import { Store } from '@ngxs/store';
import { GetContinents, GetCountries, GetDepartments, GetStates } from '@stores/locations/locations.actions';
import { LocationsState } from '@stores/locations/locations.state';
import { Observable, Subject, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-guard',
  templateUrl: './select-guard.component.html',
  styleUrls: ['./select-guard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectGuardComponent),
      multi: true,
    },
  ],
})
export class SelectGuardComponent implements ControlValueAccessor, OnDestroy, OnInit {
  @Input() isMultiple!: boolean;
  @Input() constraintType!: ConstraintType;

  private readonly destroyed$ = new Subject<void>();

  private _dependencyType!: DependencyType;

  @Input() set dependencyType(dependencyType: DependencyType) {
    this._dependencyType = dependencyType;
    this.retrieveData();
  }

  get dependencyType(): DependencyType {
    return this._dependencyType;
  }

  private _value!: string | string[];

  set value(value: string | string[]) {
    if (this._value === value) {
      return;
    }
    this._value = value;
    this._control.setValue(value);
    this.onChanged(value);
    this.onTouched();
  }

  get value(): string | string[] {
    return this._value;
  }

  private _required!: boolean;

  @Input() set required(isRequired: boolean) {
    this._control.setValidators(isRequired ? Validators.required : null);
    this._required = isRequired;
  }
  get required() {
    return this._required;
  }

  private _disabled!: boolean;

  @HostBinding('attr.disabled') @Input() set disabled(isDisabled: boolean) {
    isDisabled ? this._control.disable() : this._control.enable();
    this._disabled = isDisabled;
  }
  get disabled() {
    return this._disabled;
  }

  _control = new UntypedFormControl();

  values$!: Observable<GeoRegionSelectOption[]>;

  constructor(
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef
  ) {}

  onChanged: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  ngOnInit(): void {
    this._control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => (this.value = value));
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChanged = (value) => {
      fn(value);
      this.cdr.markForCheck();
    };
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = () => {
      fn();
      this.cdr.markForCheck();
    };
  }

  retrieveData() {
    const mapGeoRegions = (geoRegions: GeoRegion[]) => {
      return geoRegions.map((geoRegion) => {
        return {
          ISOCountry: geoRegion.countryCode,
          id: geoRegion.slug,
          label: geoRegion.slug,
        };
      });
    };

    const switchMapDepartments = switchMap(() => {
      return this.store.select(LocationsState.departments).pipe(map(mapGeoRegions));
    });

    const switchMapStates = switchMap(() => {
      return this.store.select(LocationsState.states).pipe(map(mapGeoRegions));
    });

    const switchCaseContinents = switchMap(() => {
      return this.store.select(LocationsState.continents).pipe(
        map((continents: string[]) => {
          return continents.map((continent) => {
            return {
              ISOCountry: null,
              id: continent,
              label: continent,
            };
          });
        })
      );
    });

    const switchCaseCountries = switchMap(() => {
      return this.store.select(LocationsState.countries).pipe(
        map((countries: string[]) => {
          return countries.map((country) => {
            return {
              ISOCountry: null,
              id: country,
              label: country,
            };
          });
        })
      );
    });

    const dataRetrieved: Record<TransformerTypeEnum, Observable<GeoRegionSelectOption[]>> = {
      [TransformerTypeEnum.Department_from_city]: this.store.dispatch(new GetDepartments()).pipe(switchMapDepartments),
      [TransformerTypeEnum.Continent_from_country]: this.store.dispatch(new GetContinents()).pipe(switchCaseContinents),
      [TransformerTypeEnum.State_from_city]: this.store.dispatch(new GetStates()).pipe(switchMapStates),
      [TransformerTypeEnum.Continent_from_city]: this.store.dispatch(new GetContinents()).pipe(switchCaseContinents),
      [TransformerTypeEnum.Country_from_country]: this.store.dispatch(new GetCountries()).pipe(switchCaseCountries),
      [TransformerTypeEnum.Country_from_city]: this.store.dispatch(new GetCountries()).pipe(switchCaseCountries),
      [TransformerTypeEnum.Zone_from_city]: of([
        { ISOCountry: null, id: '1', label: '1' },
        { ISOCountry: null, id: '2', label: '2' },
        { ISOCountry: null, id: '3', label: '3' },
      ]),
      [TransformerTypeEnum.Age]: of([]),
      [TransformerTypeEnum.Distance_in_km]: of([]),
      [TransformerTypeEnum.City_from_city]: of([]),
      [TransformerTypeEnum.Sequence]: of([]),
      [TransformerTypeEnum.Is_from_european_union]: of([]),
    };

    this.values$ = dataRetrieved[this.dependencyType as TransformerTypeEnum] ?? of([]);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  trackOption(index: number, option: GeoRegionSelectOption) {
    return `${index}-${option.id}`;
  }
}
