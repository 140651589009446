export class GetDepartments {
  static readonly type = '[Locations] Get Departments';
}
export class GetContinents {
  static readonly type = '[Locations] Get Continents';
}
export class GetCountries {
  static readonly type = '[Locations] Get Countries';
}
export class GetStates {
  static readonly type = '[Locations] Get States';
}
