import { buildConfig } from '@config/build-config';
import { getBuildEnvVar, getRuntimeEnvVar } from '@wizbii-utils/angular/core';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const production: boolean = buildConfig === 'production';
const applicationId = 'suggestii';

// Determine deployment platform and domain from Ansible vars
const platform = getRuntimeEnvVar('PLATFORM');
const domain = getRuntimeEnvVar('WIZBII_DOMAIN');
const wizbiiTeamDomain = getRuntimeEnvVar('WIZBII_TEAM_ENV_FQDN');

const isDeployed = !!getRuntimeEnvVar('PLATFORM');
const scheme = getRuntimeEnvVar('HTTP_SCHEME');

// Determine API platform and domain from runtime vars
const apiPlatform = getRuntimeEnvVar('API_PLATFORM');
const apiDomain = getRuntimeEnvVar('API_DOMAIN');
const suggestiiAdmin = window.location.origin;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJsonVersionAtBuild = require('../../package.json').version;

const deployAssetsUrl = production
  ? `${scheme}://storage.googleapis.com/wizbii-application-assets/suggestii/${getBuildEnvVar(
      'CI_COMMIT_SHA'
    )}/fr/assets`
  : null;

export const environment = {
  applicationId,
  platform,
  isDeployed,
  apiPlatform,
  apiDomain,
  production,
  deployAssetsUrl,
  version: production ? packageJsonVersionAtBuild : getBuildEnvVar('GIT_BRANCH_NAME'),
  bugsnagId: '397784c255dc82bb5902ea69688f378b',
  api: {
    suggestion: `${scheme}://suggestion.api.${apiDomain}`,
    account: `${scheme}://account.api.${apiDomain}`,
    authentication: `${scheme}://auth.${apiDomain}`,
    contact: `${scheme}://contact.api.${apiDomain}`,
    file: `${scheme}://file.${apiDomain}`,
    googleStorage: `${scheme}://storage.googleapis.com`,
    imaginary: `${scheme}://imaginary.${apiDomain}`,
    monitor: `${scheme}://monitor.api.${apiDomain}`,
    place: `${scheme}://place.${apiDomain}`,
    sso: `${scheme}://sso.internal.${apiDomain}`,
  },
  algolia: {
    applicationId: getRuntimeEnvVar('ALGOLIA_APP_ID'),
    apiKey: getRuntimeEnvVar('ALGOLIA_API_KEY'),
    index: {
      schools: `${apiPlatform}_onisep_schools`,
      suggestions: `${apiPlatform}_suggestions_suggestions`,
      questions: `${apiPlatform}_suggestions_questions`,
      transformers: `${apiPlatform}_suggestions_transformers`,
    },
  },
  domain: {
    wizbii: domain,
    wizbiiTeam: wizbiiTeamDomain,
    cookieDomain: window.location.hostname === 'localhost' ? 'localhost' : wizbiiTeamDomain,
  },
  urls: {
    sso: `https://sso.internal.${apiDomain}?redirect_url=${suggestiiAdmin}&appId=${applicationId}`,
  },
  wizbiiFilesBucket: getRuntimeEnvVar('WIZBII_FILES_GCS_BUCKET'),
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// if (!production) {
//   import('zone.js/dist/zone-error' as any); // Included with Angular CLI.
// }
