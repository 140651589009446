import { QuestionTriggerEnum, TriggerReturnedTypeEnum } from '@models/question';
import { DataTypeEnum, TransformerTypeEnum } from '@models/transformer';
import { QuestionType } from '@wizbii/models';

export interface FrontConstraint {
  type?: ConstraintType;
  isNot?: boolean;
  dependency: Dependency;
  guard: number | string | null;
  acceptedValues: string[] | null;
  expression?: string;
}

export interface BackConstraint {
  type?: GlobalConstraintType;
  dependency?: Dependency;
  constraint?: BackConstraint;
  guard?: number | string;
  acceptedValues?: string[] | null;
  expression?: string;
}

export enum ConstraintType {
  EqualsTo = 'equals_to', // GuardConstraint
  InferiorOrEqualsTo = 'inferior_or_equals_to', // GuardConstraint
  SuperiorOrEqualsTo = 'superior_or_equals_to', // GuardConstraint
  SuperiorTo = 'superior_to', // GuardConstraint
  InferiorTo = 'inferior_to', // GuardConstraint
  IsFalse = 'is_false', // AbstractSingleDependencyConstraint
  IsTrue = 'is_true', // AbstractSingleDependencyConstraint
  Mandatory = 'mandatory', // AbstractSingleDependencyConstraint
  Optional = 'optional', // AbstractSingleDependencyConstraint
  ExpressionLanguage = 'expression_language', // ExpressionLanguageConstraint
  AnyOf = 'any_of', // AnyOfConstraint
}

export const ConstraintTypeLabels: Record<ConstraintType, string> = {
  [ConstraintType.EqualsTo]: 'Égale à',
  [ConstraintType.ExpressionLanguage]: "Langage d'expression",
  [ConstraintType.InferiorOrEqualsTo]: 'Inférieur ou égal à',
  [ConstraintType.InferiorTo]: 'Inférieur à',
  [ConstraintType.IsFalse]: 'Faux',
  [ConstraintType.IsTrue]: 'Vrai',
  [ConstraintType.Mandatory]: 'Obligatoire',
  [ConstraintType.Optional]: 'Optionel',
  [ConstraintType.SuperiorOrEqualsTo]: 'Supérieur ou égal à',
  [ConstraintType.SuperiorTo]: 'Supérieur à',
  [ConstraintType.AnyOf]: 'Un parmi',
};
export const ShortConstraintTypeLabels: Record<ConstraintType, string> = {
  [ConstraintType.EqualsTo]: 'Égale',
  [ConstraintType.ExpressionLanguage]: "Langage d'expression",
  [ConstraintType.InferiorOrEqualsTo]: 'Inférieur ou égal',
  [ConstraintType.InferiorTo]: 'Inférieur',
  [ConstraintType.IsFalse]: 'Faux',
  [ConstraintType.IsTrue]: 'Vrai',
  [ConstraintType.Mandatory]: 'Obligatoire',
  [ConstraintType.Optional]: 'Optionel',
  [ConstraintType.SuperiorOrEqualsTo]: 'Supérieur ou égal',
  [ConstraintType.SuperiorTo]: 'Supérieur',
  [ConstraintType.AnyOf]: 'Un parmi',
};

export interface Dependency {
  type?: 'transformer' | 'question';
  id?: string;
}

export type DependencyType = QuestionType | TransformerTypeEnum;

export enum GuardType {
  Number = 'number',
  Text = 'text',
  Date = 'date',
  Choice = 'choice',
  Select = 'select',
  None = 'none',
  School = 'school',
  City = 'city',
  Document = 'document',
  Dataset = 'dataset',
}

export const GuardTypeByDependencyType: Record<DependencyType, GuardType> = {
  [QuestionType.Amount]: GuardType.Number,
  [QuestionType.Integer]: GuardType.Number,
  [TransformerTypeEnum.Age]: GuardType.Number,
  [TransformerTypeEnum.Distance_in_km]: GuardType.Number,
  [QuestionType.Choice]: GuardType.Choice,
  [QuestionType.Date]: GuardType.Date,
  [QuestionType.Text]: GuardType.Text,
  [QuestionType.Boolean]: GuardType.None,
  [QuestionType.Country]: GuardType.None,
  [QuestionType.City]: GuardType.None,
  [TransformerTypeEnum.City_from_city]: GuardType.City,
  [TransformerTypeEnum.Country_from_city]: GuardType.Select,
  [TransformerTypeEnum.Department_from_city]: GuardType.Select,
  [TransformerTypeEnum.State_from_city]: GuardType.Select,
  [TransformerTypeEnum.Zone_from_city]: GuardType.Select,
  [QuestionType.School]: GuardType.School,
  [TransformerTypeEnum.Continent_from_city]: GuardType.Select,
  [TransformerTypeEnum.Country_from_country]: GuardType.Select,
  [TransformerTypeEnum.Continent_from_country]: GuardType.Select,
  [TransformerTypeEnum.Sequence]: GuardType.Text,
  [QuestionType.Document]: GuardType.Document,
  [QuestionType.Dataset]: GuardType.Dataset,
  [QuestionType.Address]: GuardType.None,
  [QuestionType.CrousWishes]: GuardType.None,
  [TransformerTypeEnum.Is_from_european_union]: GuardType.Select,
  [QuestionType.Phone]: GuardType.None,
};

const FullyComparableDependencies: ConstraintType[] = [
  ConstraintType.Mandatory,
  ConstraintType.EqualsTo,
  ConstraintType.InferiorTo,
  ConstraintType.InferiorOrEqualsTo,
  ConstraintType.SuperiorOrEqualsTo,
  ConstraintType.SuperiorTo,
  ConstraintType.AnyOf,
];
const ComparableDependencies: ConstraintType[] = [
  ConstraintType.Mandatory,
  ConstraintType.InferiorTo,
  ConstraintType.InferiorOrEqualsTo,
  ConstraintType.SuperiorOrEqualsTo,
  ConstraintType.SuperiorTo,
];

const VanillaDependencies: ConstraintType[] = [ConstraintType.Mandatory];
const SelectableDependencies: ConstraintType[] = [
  ConstraintType.Mandatory,
  ConstraintType.EqualsTo,
  ConstraintType.AnyOf,
];

const AllDependencies: ConstraintType[] = [
  ConstraintType.Mandatory,
  ConstraintType.EqualsTo,
  ConstraintType.IsFalse,
  ConstraintType.IsTrue,
  ConstraintType.InferiorTo,
  ConstraintType.InferiorOrEqualsTo,
  ConstraintType.SuperiorOrEqualsTo,
  ConstraintType.SuperiorTo,
  ConstraintType.AnyOf,
];

export const AllowedConstraintTypesByDependencyType: Record<DependencyType, ConstraintType[]> = {
  [QuestionType.City]: VanillaDependencies,
  [QuestionType.Country]: VanillaDependencies,
  [QuestionType.Text]: VanillaDependencies,
  [QuestionType.Amount]: FullyComparableDependencies,
  [TransformerTypeEnum.Distance_in_km]: FullyComparableDependencies,
  [QuestionType.Integer]: FullyComparableDependencies,
  [QuestionType.Choice]: SelectableDependencies,
  [QuestionType.Date]: ComparableDependencies,
  [TransformerTypeEnum.Age]: [...ComparableDependencies, ConstraintType.EqualsTo],
  [TransformerTypeEnum.City_from_city]: SelectableDependencies,
  [TransformerTypeEnum.Country_from_city]: SelectableDependencies,
  [TransformerTypeEnum.Department_from_city]: SelectableDependencies,
  [TransformerTypeEnum.State_from_city]: SelectableDependencies,
  [TransformerTypeEnum.Zone_from_city]: SelectableDependencies,
  [QuestionType.Boolean]: [ConstraintType.Mandatory, ConstraintType.IsFalse, ConstraintType.IsTrue],
  [QuestionType.School]: SelectableDependencies,
  [TransformerTypeEnum.Continent_from_city]: SelectableDependencies,
  [TransformerTypeEnum.Country_from_country]: SelectableDependencies,
  [TransformerTypeEnum.Continent_from_country]: SelectableDependencies,
  [TransformerTypeEnum.Sequence]: AllDependencies,
  [QuestionType.Document]: VanillaDependencies,
  [QuestionType.Dataset]: VanillaDependencies,
  [QuestionType.Address]: VanillaDependencies,
  [QuestionType.CrousWishes]: VanillaDependencies,
  [TransformerTypeEnum.Is_from_european_union]: [
    ConstraintType.Mandatory,
    ConstraintType.IsFalse,
    ConstraintType.IsTrue,
  ],
  [QuestionType.Phone]: VanillaDependencies,
};

export const AllowedConstraintTypesByDataType: Record<DataTypeEnum, ConstraintType[]> = {
  [DataTypeEnum.Integer]: FullyComparableDependencies,
  [DataTypeEnum.Boolean]: [ConstraintType.Mandatory, ConstraintType.IsFalse, ConstraintType.IsTrue],
  [DataTypeEnum.Float]: FullyComparableDependencies,
  [DataTypeEnum.String]: SelectableDependencies,
};

export interface RootConstraint {
  type: GlobalConstraintType | QuestionTriggerEnum;
  constraints?: BackConstraint[];
}

export function decodeConstraints(original: RootConstraint): FrontConstraint[] {
  const constraints: any[] = [];
  original.constraints?.forEach((constraint) => {
    if (constraint.type === 'not') {
      constraints.push({
        isNot: true,
        ...constraint.constraint,
      });
    } else {
      constraints.push({
        isNot: false,
        ...constraint,
      });
    }
  });
  return constraints;
}

export function encodeConstraints(original: FrontConstraint[] | undefined): RootConstraint | undefined {
  if (!original) {
    return undefined;
  }

  const constraints = [...original];
  return {
    type: 'and',
    constraints: constraints.map((constraint) => {
      const newConstraint = { ...constraint };
      if (constraint.isNot) {
        delete newConstraint.isNot;
        return {
          type: 'not',
          constraint: newConstraint as BackConstraint,
        };
      }
      delete newConstraint.isNot;
      return newConstraint as BackConstraint;
    }),
  };
}

export type GlobalConstraintType = 'and' | 'not' | ConstraintType;

export enum GuardPropertyName {
  MultipleValues = 'acceptedValues',
  SingleValue = 'guard',
}

export interface ReturnedValueConstraint {
  type: TriggerReturnedTypeEnum;
  value?: any;
}

export interface TriggerConstraint {
  type: QuestionTriggerEnum;
  returnedValue?: ReturnedValueConstraint;
  constraint?: RootConstraint;
}

export function buildConstraintsForm(constraint: RootConstraint | undefined): FrontConstraint[] {
  const groups: any[] = [];
  if (constraint) {
    const frontConstraints = decodeConstraints(constraint);
    frontConstraints.forEach((f) => {
      groups.push(buildConstraint(f));
    });
  } else {
    groups.push(buildConstraint());
  }
  return groups;
}

export function buildConstraint(constraint?: FrontConstraint): FrontConstraint {
  const group: FrontConstraint = {
    type: constraint?.type ?? undefined,
    isNot: constraint?.isNot ?? false,
    dependency: {
      id: constraint?.dependency.id ?? undefined,
      type: constraint?.dependency.type ?? undefined,
    },
    acceptedValues: constraint?.acceptedValues ?? null,
    guard: constraint?.guard ?? null,
  };

  if (constraint?.expression) {
    group.expression = constraint.expression;
  }

  return group;
}
