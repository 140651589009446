import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-resource-not-found',
  templateUrl: './resource-not-found.component.html',
  styleUrls: ['./resource-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceNotFoundComponent {
  constructor(
    readonly route: ActivatedRoute,
    readonly router: Router,
    private readonly location: Location
  ) {}

  goBack() {
    const redirection = this.route.snapshot.queryParamMap.get('redirect');
    redirection ? this.router.navigateByUrl(redirection) : this.location.back();
  }
}
