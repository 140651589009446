import { Directive, ElementRef, EventEmitter, HostListener, NgModule, Output } from '@angular/core';

@Directive({
  selector: '[appTextDateInput]',
})
export class TextDateInputDirective {
  @Output() dateUpdated = new EventEmitter<{ day: number; month: number; year: number }>();

  constructor(private readonly el: ElementRef<HTMLInputElement>) {}

  checkValue(str, max) {
    let res = str;
    if (res.charAt(0) !== '0' || res === '00') {
      let num = parseInt(res, 10);
      if (isNaN(num) || num <= 0 || num > max) {
        num = 1;
      }
      res = num > parseInt(max.toString().charAt(0), 10) && num.toString().length === 1 ? `0${num}` : num.toString();
    }
    return res;
  }

  setValue(day: number, month: number, year: number) {
    this.el.nativeElement.value = `${day}/${month}/${year}`;
    this.onBlur();
  }

  @HostListener('focus') onFocus() {
    this.el.nativeElement.select();
  }

  @HostListener('blur') onBlur() {
    const input = this.el.nativeElement.value;
    const values = input.split('/').map((v) => {
      return v.replace(/\D/g, '');
    });
    let output = '';

    if (values.length === 3) {
      const year = values[2].length !== 4 ? parseInt(values[2], 10) + 2000 : parseInt(values[2], 10);
      const day = parseInt(values[0], 10);
      const month = parseInt(values[1], 10);
      const d = new Date(year, month - 1, day);
      if (!isNaN(d.getTime())) {
        const dates = [d.getDate(), d.getMonth() + 1, d.getFullYear()];
        output = dates
          .map((v) => {
            const vv = v.toString();
            return vv.length === 1 ? `0${v}` : v;
          })
          .join(' / ');
        this.dateUpdated.emit({ day, month, year });
      }
    } else {
      this.dateUpdated.emit(null);
    }
    this.el.nativeElement.value = output;
  }

  @HostListener('input') onKeydown() {
    let input = this.el.nativeElement.value;
    if (/\D\/$/.test(input)) {
      input = input.substr(0, input.length - 3);
    }
    const values = input.split('/').map((v) => {
      return v.replace(/\D/g, '');
    });
    if (!values.length || !values[0]) {
      this.dateUpdated.emit(null);
    }
    if (values[0]) {
      values[0] = this.checkValue(values[0], 31);
    }
    if (values[1]) {
      values[1] = this.checkValue(values[1], 12);
    }
    const output = values.map((v, i) => {
      return v.length === 2 && i < 2 ? `${v} / ` : v;
    });
    this.el.nativeElement.value = output.join('').substr(0, 14);
  }
}

@NgModule({
  declarations: [TextDateInputDirective],
  exports: [TextDateInputDirective],
})
export class TextDateInputModule {}
