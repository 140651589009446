@if (values$ | async; as values) {
  <ng-select
    class="autocomplete"
    [placeholder]="isMultiple ? 'Valeurs' : 'Valeur'"
    [formControl]="_control"
    [virtualScroll]="true"
    [required]="true"
    [multiple]="isMultiple"
    [closeOnSelect]="!isMultiple"
    appearance="outline"
    dropdownPosition="auto"
    notFoundText="Aucun résultat"
    clearAllText="Effacer"
    appendTo="body"
  >
    @for (option of values; track trackOption($index, option)) {
      <ng-option [value]="option.id">
        <span class="fi fi-{{ option.ISOCountry | lowercase }} "></span> {{ option.label | titlecase }}
      </ng-option>
    }
  </ng-select>
}
