import { TriggerConstraint } from '@models/constraint';
import { Revision } from '@models/revision';
import { TranslationMap } from '@models/theme';
import { LangEnum, QuestionType } from '@wizbii/models';
import { InputTypeEnum } from '@wizbii/models/lib/suggestions-api/enums/input-type.enum';

export const LabelByQuestionType: Record<QuestionType, string> = {
  [QuestionType.Amount]: 'Montant',
  [QuestionType.Boolean]: 'Booléen',
  [QuestionType.Choice]: 'Choix',
  [QuestionType.City]: 'Ville',
  [QuestionType.Country]: 'Pays',
  [QuestionType.Date]: 'Date',
  [QuestionType.Integer]: 'Nombre',
  [QuestionType.School]: 'École',
  [QuestionType.Text]: 'Texte',
  [QuestionType.Document]: 'Document',
  [QuestionType.Dataset]: 'Ensembles de données',
  [QuestionType.Address]: 'Adresse',
  [QuestionType.CrousWishes]: 'Voeux CROUS',
  [QuestionType.Phone]: 'Téléphone',
};

export interface Question {
  id: string;
  questionId: string;
  type: QuestionType;
  revision: Revision;
  themeId: string;
  priority: number;
  name: string;
  description?: string;
  labels: TranslationMap;
  tips: TranslationMap;
  placeholders: TranslationMap;
  trigger: TriggerConstraint;
  descriptionDepositForm?: string;
  questionTags: QuestionTagEnum[];
  isOptional: boolean;
}
export enum QuestionTagEnum {
  Sensitive = 'sensitive',
  Banker = 'banker',
  KYC = 'kyc',
}

export const QuestionTagLabels: Record<QuestionTagEnum, string> = {
  [QuestionTagEnum.Banker]: 'Donnée bancaire',
  [QuestionTagEnum.Sensitive]: 'Donnée sensible',
  [QuestionTagEnum.KYC]: 'Donnée d’identité',
};

export enum CurrencyEnum {
  Dollar = 'USD',
  Euro = 'EUR',
  Pound = 'GBP',
}

export interface AmountQuestion extends Question {
  currency: CurrencyEnum;
  minValue?: number;
  maxValue: number;
}

export type Choices = {
  [key in LangEnum]?: {
    id: string;
    label: string;
    value?: string;
  }[];
};

export interface ChoiceQuestion extends Question {
  multiple: boolean;
  possibleValues: Choices;
  widgetType: ChoiceWidgetType;
}

export interface DateQuestion extends Question {
  pickFromDay: number;
  pickFromMonth: number;
  pickFromYear?: number;
}

export interface IntegerQuestion extends Question {
  minValue?: number;
  maxValue: number;
  widgetType: NumberWidgetType;
}

export interface TextQuestion extends Question {
  inputType: InputTypeEnum;
  minLength?: number;
  maxLength: number;
  regex: string | null;
}

export interface DatasetQuestion extends Question {
  datasetQuestions: DatasetEntry[];
  datasetMin: number;
  datasetMax: number;
  buttonLabel: string;
}

export interface DatasetEntry {
  questionId: string;
  trigger: TriggerConstraint;
}

export enum NumberWidgetType {
  Input = 'input',
  Button = 'button',
}
export enum ChoiceWidgetType {
  Select = 'select',
  Button = 'button',
}

export const LabelByWidgetType: Record<NumberWidgetType | ChoiceWidgetType, string> = {
  [NumberWidgetType.Button]: 'Boutons',
  [NumberWidgetType.Input]: 'Champ de texte',
  [ChoiceWidgetType.Select]: 'Menu déroulant',
};

export const LabelByInputType: Record<InputTypeEnum, string> = {
  [InputTypeEnum.Password]: 'Mot de passe',
  [InputTypeEnum.Text]: 'Champ de texte',
};

export enum QuestionTriggerEnum {
  Always = 'always',
  OnlyIf = 'only_if',
}

export const LabelByQuestionTrigger: Record<QuestionTriggerEnum, string> = {
  [QuestionTriggerEnum.Always]: 'Tout le temps',
  [QuestionTriggerEnum.OnlyIf]: 'Seulement si',
};

export enum TriggerReturnedTypeEnum {
  Value = 'value',
  None = 'null',
}

export const LabelByTriggerReturnedType: Record<TriggerReturnedTypeEnum, string> = {
  [TriggerReturnedTypeEnum.Value]: 'Définir une valeur fixe',
  [TriggerReturnedTypeEnum.None]: 'Ne rien définir',
};
