import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-number-guard',
  templateUrl: './number-guard.component.html',
  styleUrls: ['./number-guard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberGuardComponent),
      multi: true,
    },
  ],
})
export class NumberGuardComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();

  private _value: number;

  set value(value: number) {
    if (this._value === value) {
      return;
    }
    this._value = value;
    this._control.setValue(value);
    this.onChanged(value);
    this.onTouched();
  }
  get value(): number {
    return this._value;
  }

  private _required: boolean;

  @Input() set required(isRequired: boolean) {
    this._control.setValidators(isRequired ? Validators.required : null);
    this._required = isRequired;
  }
  get required() {
    return this._required;
  }

  private _disabled: boolean;

  @HostBinding('attr.disabled') @Input() set disabled(isDisabled: boolean) {
    isDisabled ? this._control.disable() : this._control.enable();
    this._disabled = isDisabled;
  }
  get disabled() {
    return this._disabled;
  }

  _control = new UntypedFormControl();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onChanged: (_: number) => void = (_: number) => {};
  onTouched: () => void = () => {};

  ngOnInit(): void {
    this._control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => (this.value = value));
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChanged = (value) => {
      fn(value);
      this.cdr.markForCheck();
    };
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = () => {
      fn();
      this.cdr.markForCheck();
    };
  }
  setDisabledState = (isDisabled: boolean) => {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  };
  writeValue = (value: number) => (this.value = value);

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
