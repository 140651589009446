import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextDateInputDirective } from '@domains/directives/text-date-input-directive';

@Component({
  selector: 'app-date-guard',
  templateUrl: './date-guard.component.html',
  styleUrls: ['./date-guard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateGuardComponent),
      multi: true,
    },
  ],
})
export class DateGuardComponent implements AfterViewInit {
  @ViewChild(TextDateInputDirective) dateInput: TextDateInputDirective;

  private _value: string;

  set value(value: string) {
    if (this._value === value) {
      return;
    }

    this._value = value;
    this.setDate(value);

    this.onChanged(value);
    this.onTouched();
  }
  get value(): string {
    return this._value;
  }

  private _required: boolean;

  @Input() set required(isRequired: boolean) {
    this._required = isRequired;
  }
  get required(): boolean {
    return this._required;
  }

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onChanged: (_: string) => void = (_: string) => {};
  onTouched: () => void = () => {};

  ngAfterViewInit(): void {
    this.setDate(this.value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChanged = (value) => {
      fn(value);
      this.cdr.markForCheck();
    };
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = () => {
      fn();
      this.cdr.markForCheck();
    };
  }

  writeValue = (value: string) => (this.value = value);

  setDate(date: string) {
    if (date) {
      const parsedDate = date.split('/').map((i) => parseInt(i, 10));
      if (parsedDate.length === 3) {
        this.dateInput.setValue(parsedDate[0], parsedDate[1], parsedDate[2]);
      }
    }
  }

  updateDateValue(value: { day: number; month: number; year: number }) {
    this.value = value ? `${value.day}/${value.month}/${value.year}` : undefined;
  }
}
