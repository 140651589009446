import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { bugsnagConfig } from '@config/bugsnag.config';
import { CoreComponent } from '@core/components/core/core.component';
import { AppDialogs } from '@core/components/dialogs/dialogs';
import { SpriteComponent } from '@core/components/sprite/sprite.component';
import { AlgoliaCredentialFetcherService } from '@core/services/algolia/algolia-credential-fetcher.service';
import { DialogService } from '@core/services/dialog/dialog.service';
import { JwtService } from '@core/services/jwt.service';
import { WizbiiRouterStateSerializer } from '@core/services/router-state-serializer.service';
import { ConstraintDialogModule } from '@domains/constraints/constraint-dialog/constraint-dialog.component';
import { environment } from '@environment';
import { FeaturesModule } from '@features/features.module';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { LocationWebservice } from '@webservices/location-api/location.webservice';
import { SuggestionWebservice } from '@webservices/suggestion-api/suggestion.webservice';
import {
  FILE_PICKER_SERVICE_TOKEN,
  FileWebService,
  MUST_BE_AUTHORIZED_GUARD_CONFIG,
  PageUnauthorizedModule,
} from '@wizbii-components/bo-angular-ui';
import { WzbBoNotificationsComponent } from '@wizbii-components/bo-angular-ui';
import { ImageService, WINDOW_PROVIDERS } from '@wizbii-utils/angular/core';
import { JWT_STATE_CONFIG, JwtState, JwtStateModule } from '@wizbii-utils/angular/stores';
import { FILE_API_CONFIG, FileWebservice } from '@wizbii-utils/angular/webservices';
import {
  ALGOLIA_CREDENTIALS_FETCHERS_TOKEN,
  AlgoliaClientProvider,
  AlgoliaModule,
  CredentialsFetcher,
} from '@wizbii/algolia';
import { bugsnagErrorHandlerFactory } from '@wizbii/angular-bugsnag';
import { JWT_SERVICE_TOKEN, JwtInterceptor } from '@wizbii/jwt';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

@NgModule({
  imports: [QuicklinkModule],
  exports: [QuicklinkModule],
  declarations: [],
})
export class QuicklinkSharedModule {}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    QuicklinkSharedModule,
    RouterModule.forRoot([], {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      preloadingStrategy: QuicklinkStrategy,
    }),
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    WzbBoNotificationsComponent,
    HttpClientModule,
    AlgoliaModule,
    MatProgressBarModule,
    MatDialogModule,
    PageUnauthorizedModule,
    FeaturesModule,
    ReactiveFormsModule,
    JwtStateModule,
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'Wizbii Suggestii',
      maxAge: 30,
    }),
    MatInputModule,
    ConstraintDialogModule,
  ],
  providers: [
    {
      provide: JWT_STATE_CONFIG,
      useValue: {
        jwtCookieName: 'wizbii_bo',
        expiryCookieName: 'wizbii_bo_expiry',
        appEnvFqdn: environment.domain.cookieDomain,
      },
    },
    DialogService,
    SuggestionWebservice,
    {
      provide: ErrorHandler,
      useFactory: bugsnagErrorHandlerFactory(bugsnagConfig),
    },
    { provide: RouterStateSerializer, useClass: WizbiiRouterStateSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: JWT_SERVICE_TOKEN, useClass: JwtService },
    { provide: LOCALE_ID, useValue: 'fr' },
    WINDOW_PROVIDERS,
    {
      provide: ImageService,
      useValue: new ImageService(environment.api.imaginary),
    },
    AlgoliaCredentialFetcherService,
    {
      provide: ALGOLIA_CREDENTIALS_FETCHERS_TOKEN,
      useFactory: (customerCredentialsFetcherService: CredentialsFetcher) => ({
        main: customerCredentialsFetcherService,
      }),
      deps: [AlgoliaCredentialFetcherService],
    },
    AlgoliaClientProvider,
    LocationWebservice,
    {
      provide: FILE_API_CONFIG,
      useValue: {
        baseUrl: `https://file.${environment.apiDomain}`,
        googleStorageUrl: 'https://storage.googleapis.com',
        wizbiiFilesBucket: environment.wizbiiFilesBucket,
      },
    },
    {
      provide: MUST_BE_AUTHORIZED_GUARD_CONFIG,
      useFactory: (store: Store) => ({
        listRoles$: store.select(JwtState.roles),
        listAuthorizedRoles: ['ROLE_SUGGESTII_MANAGER'],
        platform: environment.platform,
      }),
      deps: [Store],
    },
    FileWebservice,
    {
      provide: FILE_PICKER_SERVICE_TOKEN,
      useClass: FileWebService,
    },
  ],
  declarations: [CoreComponent, SpriteComponent, ...AppDialogs],
  bootstrap: [CoreComponent],
})
export class CoreModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    const domain = environment.deployAssetsUrl ?? '/assets';

    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl(`${domain}/sprite.svg`));
  }
}
