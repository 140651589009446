import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, forwardRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { ConstraintType, GuardType, GuardTypeByDependencyType } from '@models/constraint';
import { PickableDependencyItem } from '@models/pickable-dependency-item';

@Component({
  selector: 'app-guards-holder',
  templateUrl: './guards-holder.component.html',
  styleUrls: ['./guards-holder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GuardsHolderComponent),
      multi: true,
    },
  ],
})
export class GuardsHolderComponent implements ControlValueAccessor, OnInit {
  @Input() dependency: Partial<PickableDependencyItem>;
  @Input() constraintType: ConstraintType;

  readonly #destroyRef = inject(DestroyRef);

  get isComparable() {
    return !this.incomparableConstraints.includes(this.constraintType);
  }

  get isMultiple() {
    return this.constraintType === ConstraintType.AnyOf;
  }

  get dependencyType() {
    return this.dependency.type;
  }

  get currentGuard() {
    return this.dependencyType ? GuardTypeByDependencyType[this.dependencyType] : null;
  }

  set value(value: any) {
    if (value === this._value) {
      return;
    }

    this._value = value;
    this._control.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get value(): any {
    return this._value;
  }

  incomparableConstraints = [
    ConstraintType.Mandatory,
    ConstraintType.Optional,
    ConstraintType.IsTrue,
    ConstraintType.IsFalse,
  ];
  GuardTypeEnum = GuardType;

  private _value: any;
  _control = new UntypedFormControl();

  ngOnInit(): void {
    this._control.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe({
      next: (value) => (this.value = value),
    });
  }

  private onChange = (_: any) => {};
  private onTouched = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }
  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
