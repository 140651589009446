<main class="container">
  <div class="menu">
    <wzb-bo-navigation-sidebar-menu
      [items]="allItems"
      [activeRoute]="activeRoute"
      [reduced]="true"
      [reducible]="false"
      (itemClicked)="handleItemClicked($event); activeRoute = $event.route"
      (actionClicked)="handleItemClicked($event)"
    >
      <a class="logo-icon" menuLogo routerLink="/">
        <svg width="54" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="currentColor"
            d="M48 24C48 37.2548 37.2548 48 24 48C20.3784 48 16.9441 47.1978 13.8652 45.7614L10.588 47.9252C9.21268 48.8333 7.3909 47.7815 7.48965 46.1363L7.75778 41.6691C2.9886 37.2828 0 30.9904 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24Z"
          />
          <path
            fill-rule="evenodd"
            d="M17.8269 17.4659C17.2764 16.966 17 16.3482 17 15.6079C17 14.8676 17.274 14.2475 17.8269 13.7499C18.3773 13.25 19.1008 13 19.9949 13C20.8891 13 21.6126 13.238 22.163 13.7091C22.7134 14.1826 22.9899 14.7883 22.9899 15.5286C22.9899 16.3026 22.7134 16.9443 22.163 17.4515C21.6126 17.9587 20.8891 18.2135 19.9949 18.2135C19.1008 18.2159 18.3773 17.9659 17.8269 17.4659ZM17.5432 19.7638H22.449V33.7577H17.5432V19.7638Z"
            fill="#5750FB"
          />
          <path
            fill-rule="evenodd"
            d="M25.9079 17.4659C25.3575 16.966 25.0811 16.3482 25.0811 15.6079C25.0811 14.8676 25.3551 14.2475 25.9079 13.7499C26.4583 13.25 27.1818 13 28.076 13C28.9701 13 29.6936 13.238 30.244 13.7091C30.7945 14.1826 31.0709 14.7883 31.0709 15.5286C31.0709 16.3026 30.7945 16.9443 30.244 17.4515C29.6936 17.9587 28.9701 18.2135 28.076 18.2135C27.1818 18.2159 26.4583 17.9659 25.9079 17.4659ZM25.6243 19.7638H30.5301V33.7577H25.6243V19.7638Z"
            fill="#5750FB"
          />
        </svg>
      </a>

      @for (icon of iconList; track trackByIcon($index, icon)) {
        <mat-icon *wzbBoNavigationSidebarMenuIcon="icon!" [svgIcon]="icon!" width="1.125em" />
      }

      <wiz-sidebar [environment]="env" menuOption />
    </wzb-bo-navigation-sidebar-menu>
  </div>

  <div class="content">
    <router-outlet />
  </div>
</main>
