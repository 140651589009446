<div class="main">
  <div class="left">
    <div class="title">404</div>
  </div>
  <div class="right">
    <div class="title">Cette ressource n'existe pas.</div>
    <div class="subtitle">
      <a class="btn" (click)="goBack()">
        <mat-icon class="btn-icon" svgIcon="left" width="1.2em" />
        Retour</a
      >
    </div>
  </div>
</div>
