import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommitOptions } from '@models/commons/utils';
import latinize from 'latinize';

@Component({
  selector: 'app-commit-message-dialog',
  templateUrl: './commit-message-dialog.component.html',
  styleUrls: ['./commit-message-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommitMessageDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<CommitMessageDialogComponent, CommitOptions>) {}

  submit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    const value: CommitOptions = this.formGroup.value;
    value.message = latinize(value.message);
    this.dialogRef.close(this.formGroup.value);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({ message: new UntypedFormControl() });
  }
}
