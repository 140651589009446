import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@environment';
import { Store } from '@ngxs/store';
import { JwtState } from '@wizbii-utils/angular/stores';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotLoggedGuard {
  constructor(readonly store: Store) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const isLogged$: Observable<boolean> = this.store.select(JwtState.isLogged);
    const hasLoginToken$: Observable<boolean> = of(route.queryParamMap.has('login-token'));

    return combineLatest([isLogged$, hasLoginToken$]).pipe(
      map(([isLogged, hasLoginToken]) => {
        if (!isLogged && !hasLoginToken) {
          window.open(environment.urls.sso, '_self');
          return false;
        }
        return true;
      })
    );
  }
}
