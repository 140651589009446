import { SearchResponse } from '@algolia/client-search';
import { Injectable } from '@angular/core';
import { AlgoliaClientProvider } from '@wizbii/algolia';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AlgoliaService {
  constructor(private readonly algoliaClientProvider: AlgoliaClientProvider) {}

  fetchIndex<T>(
    indexId: string,
    query = '',
    facetFilters: string[][] = [],
    hitsPerPage = 20,
    page = 0
    // New signature to be used when migration will fully be completed
    // {
    //     indexId,
    //     query,
    //     facetFilters,
    //     hitsPerPage,
    //     page
    // }: { indexId: string, query?: string, facetFilters?: string[], hitsPerPage?: number, page?: number } = {
    //     indexId: '',
    //     query: '',
    //     facetFilters: [],
    //     hitsPerPage: 50,
    //     page: 0
    // }
  ): Observable<SearchResponse<T>> {
    return this.algoliaClientProvider.getClient('main').pipe(
      map((client) => client.initIndex(indexId)),
      switchMap((index) => {
        return from(
          index.search<T>(query, {
            facetFilters,
            page,
            hitsPerPage,
            facets: ['*'],
            cacheable: false,
          })
        );
      })
    );
  }
}
