<div class="dialog">
  <h4 class="dialog__title" mat-dialog-title>Enregistrez vos modifications</h4>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-dialog-content>
      <div class="dialog__description">
        Ajoutez un message pour décrire les modifiations avant de sauvegarder :&nbsp;
      </div>

      <div class="dialog__form-row">
        <mat-form-field class="dialog__form-row__input" appearance="outline">
          <input cdkFocusInitial matInput formControlName="message" required placeholder="Description" />
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <div class="dialog__actions" mat-dialog-actions>
      <button class="btn btn--outline" (click)="cancel()" type="button">Annuler</button>
      <button class="btn" type="submit">Enregistrer</button>
    </div>
  </form>
</div>
