<h4 class="header__title" mat-dialog-title>Editer une contrainte</h4>
<form [formGroup]="constraintFormGroup" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field class="form__input" formGroupName="dependency" appearance="outline">
      <mat-label>Question / Transformer</mat-label>
      <input [matAutocomplete]="auto" type="text" matInput formControlName="id" />
      <mat-autocomplete
        class="constraint-panel"
        #auto="matAutocomplete"
        autoActiveFirstOption
        matAutocompletePosition="below"
      >
        @if (searchDependencies$ | async; as dependencyItems) {
          @for (dependency of dependencyItems; track trackByPickableDependencyItem($index, dependency)) {
            <mat-option [value]="dependency.id">
              <div class="dependency">
                <div class="dependency__header">
                  <span class="dependency__header__title">{{ dependency.name }}</span>
                  <span class="dependency__header__type">
                    {{
                      dependency.resourceType === 'question'
                        ? questionTypeLabels[dependency.type]
                        : transformerTypeLabels[dependency.type]
                    }}</span
                  >
                </div>
                @if (dependency.description) {
                  <div class="dependency__body">
                    {{ dependency.description }}
                  </div>
                }
              </div>
            </mat-option>
          }
        }
      </mat-autocomplete>
    </mat-form-field>
    <div class="row">
      <button
        class="btn btn--outline dependency-btn"
        (click)="toggleConstraintNegation()"
        mat-button
        color="primary"
        type="button"
      >
        {{ constraintNegation === true ? "N'est pas" : 'Est' }}
      </button>
      <mat-form-field class="form__input" appearance="outline">
        <mat-label>Contrainte</mat-label>
        <mat-select formControlName="type" required>
          @if ((currentDependency$ | async) && (isQuestionOrNonSequenceTransformer$ | async) === true) {
            @for (
              constraint of constraintsByDep[(currentDependency$ | async)?.type];
              track trackByValue($index, constraint)
            ) {
              <mat-option [value]="constraint">
                {{ constraintTypeLabels[constraint] }}
              </mat-option>
            }
          } @else {
            @for (
              constraint of constraintByDataType[(transformer$ | async)?.dataType];
              track trackByValue($index, constraint)
            ) {
              <mat-option [value]="constraint">
                {{ constraintTypeLabels[constraint] }}
              </mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
      @if (!isMultipleValues) {
        <app-guards-holder
          class="form__input"
          [dependency]="currentDependency$ | async"
          [constraintType]="currentConstraintType"
          [formControl]="guardControl"
        />
      }
      @if (isMultipleValues) {
        <app-guards-holder
          class="form__input"
          [dependency]="currentDependency$ | async"
          [constraintType]="currentConstraintType"
          [formControl]="acceptedValuesControl"
        />
      }
    </div>
  </mat-dialog-content>

  <div class="dialog__actions" mat-dialog-actions>
    <button class="wzb-btn wzb-btn--outline" (click)="dialogRef.close()" type="button">Annuler</button>
    <button class="wzb-btn save" type="submit">Enregistrer</button>
  </div>
</form>
