import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

export enum StatementTypeEnum {
  AssignVariable = 'assign_variable',
  AssignVariableUsingRanges = 'assign_variable_using_ranges',
  IncrementVariable = 'increment_variable',
  DecrementVariable = 'decrement_variable',
  Return = 'return',
  Conditional = 'conditional',
}

export interface Statement {
  type: StatementTypeEnum;
}

export interface AssignVariableStatement extends Statement {
  variableName: string;
  variableExpression: string;
}

export interface AssignVariableUsingRangeStatement extends Statement {
  variableName: string;
  defaultValueExpression: string;
  lineSelectorExpression: string;
  lineSelectorConstraint: StatementConstraint;
  lineSelectorAlgorithm: SelectorAlgorithm;
  columnSelectorExpression: string;
  columnSelectorConstraint: StatementConstraint;
  columnSelectorAlgorithm: SelectorAlgorithm;
  possibleValues: string[];
  cells: string[][];
}

export enum SelectorAlgorithm {
  Last = 'last',
  First = 'first',
}

export interface IncrementVariableStatement extends Statement {
  variableName: string;
  pitchExpression: string;
}

export interface DecrementVariableStatement extends Statement {
  variableName: string;
  pitchExpression: string;
}

export interface ReturnStatement extends Statement {
  expression: string;
}

export interface ConditionalStatement extends Statement {
  branchResources: ConditionalBranch[];
  defaultStatementResource?: BranchableStatements;
}

export interface ConditionalBranch {
  conditionExpression: string;
  statementResource: BranchableStatements;
}

export type BranchableStatements =
  | AssignVariableStatement
  | AssignVariableUsingRangeStatement
  | IncrementVariableStatement
  | DecrementVariableStatement
  | ReturnStatement;

export type StatementConstraint =
  | StatementConstraintType.InferiorOrEqualsTo
  | StatementConstraintType.EqualsTo
  | StatementConstraintType.SuperiorOrEqualsTo;

const assignVariableUsingRangesFieldNames = [
  'variableName',
  'defaultValueExpression',
  'lineSelectorExpression',
  'lineSelectorConstraint',
  'lineSelectorAlgorithm',
  'columnSelectorExpression',
  'columnSelectorConstraint',
  'columnSelectorAlgorithm',
];

const assignVariableUsingRangesDefaultCells = [
  ['Ligne 1', 0, 0, 0],
  ['Ligne 2', 0, 0, 0],
  ['Ligne 3', 0, 0, 0],
];
const assignVariableUsingRangesDefaultColumns = ['Colonne 1', 'Colonne 2', 'Colonne 3'];

export function baseForms(fb: UntypedFormBuilder): Record<StatementTypeEnum, Record<string, any>> {
  return {
    [StatementTypeEnum.AssignVariable]: {
      type: [StatementTypeEnum.AssignVariable],
      variableName: [],
      variableExpression: [],
    },
    [StatementTypeEnum.IncrementVariable]: {
      type: [StatementTypeEnum.IncrementVariable],
      variableName: [],
      pitchExpression: [],
    },
    [StatementTypeEnum.DecrementVariable]: {
      type: [StatementTypeEnum.DecrementVariable],
      variableName: [],
      pitchExpression: [],
    },
    [StatementTypeEnum.Return]: {
      type: [StatementTypeEnum.Return],
      expression: [],
    },
    [StatementTypeEnum.Conditional]: {
      type: [StatementTypeEnum.Conditional],
      branchResources: fb.array([]),
    },
    [StatementTypeEnum.AssignVariableUsingRanges]: {
      type: new UntypedFormControl(StatementTypeEnum.AssignVariableUsingRanges),
      cells: new UntypedFormControl(assignVariableUsingRangesDefaultCells),
      possibleValues: new UntypedFormControl(assignVariableUsingRangesDefaultColumns),
      ...assignVariableUsingRangesFieldNames.reduce<any>(
        (acc, current) => ({
          ...acc,
          [current]: [],
        }),
        {}
      ),
    },
  };
}

export function baseFormsConditional(): Partial<Record<StatementTypeEnum, Record<string, any>>> {
  return {
    [StatementTypeEnum.AssignVariable]: {
      type: [StatementTypeEnum.AssignVariable],
      variableName: [],
      variableExpression: [],
    },
    [StatementTypeEnum.IncrementVariable]: {
      type: [StatementTypeEnum.IncrementVariable],
      variableName: [],
      pitchExpression: [],
    },
    [StatementTypeEnum.DecrementVariable]: {
      type: [StatementTypeEnum.DecrementVariable],
      variableName: [],
      pitchExpression: [],
    },
    [StatementTypeEnum.Return]: {
      type: [StatementTypeEnum.Return],
      expression: [],
    },
    [StatementTypeEnum.AssignVariableUsingRanges]: {
      type: new UntypedFormControl(StatementTypeEnum.AssignVariableUsingRanges),
      cells: new UntypedFormControl(assignVariableUsingRangesDefaultCells),
      possibleValues: new UntypedFormControl(assignVariableUsingRangesDefaultColumns),
      ...assignVariableUsingRangesFieldNames.reduce<any>(
        (acc, current) => ({
          ...acc,
          [current]: [],
        }),
        {}
      ),
    },
  };
}

export enum StatementConstraintType {
  EqualsTo = 'equals-to',
  InferiorOrEqualsTo = 'inferior-or-equals-to',
  SuperiorOrEqualsTo = 'superior-or-equals-to',
  SuperiorTo = 'superior-to',
  InferiorTo = 'inferior-to',
  IsFalse = 'is-false',
  IsTrue = 'is-true',
  Mandatory = 'mandatory',
  Optional = 'optional',
  ExpressionLanguage = 'expression-language',
  AnyOf = 'any-of',
}

export const StatementConstraintTypeLabels: Record<StatementConstraintType, string> = {
  [StatementConstraintType.EqualsTo]: 'Égale',
  [StatementConstraintType.ExpressionLanguage]: "Langage d'expression",
  [StatementConstraintType.InferiorOrEqualsTo]: 'Inférieur ou égal',
  [StatementConstraintType.InferiorTo]: 'Inférieur',
  [StatementConstraintType.IsFalse]: 'Faux',
  [StatementConstraintType.IsTrue]: 'Vrai',
  [StatementConstraintType.Mandatory]: 'Obligatoire',
  [StatementConstraintType.Optional]: 'Optionel',
  [StatementConstraintType.SuperiorOrEqualsTo]: 'Supérieur ou égal',
  [StatementConstraintType.SuperiorTo]: 'Supérieur',
  [StatementConstraintType.AnyOf]: 'Un parmi',
};
