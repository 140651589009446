import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ChoiceGuardComponent } from '@domains/constraints/constraints-holder/guards-holder/choice-guard/choice-guard.component';
import { CityGuardComponent } from '@domains/constraints/constraints-holder/guards-holder/city-guard/city-guard.component';
import { DateGuardComponent } from '@domains/constraints/constraints-holder/guards-holder/date-guard/date-guard.component';
import { GuardsHolderComponent } from '@domains/constraints/constraints-holder/guards-holder/guards-holder.component';
import { NumberGuardComponent } from '@domains/constraints/constraints-holder/guards-holder/number-guard/number-guard.component';
import { SchoolGuardComponent } from '@domains/constraints/constraints-holder/guards-holder/school-guard/school-guard.component';
import { SelectGuardComponent } from '@domains/constraints/constraints-holder/guards-holder/select-guard/select-guard.component';
import { TextGuardComponent } from '@domains/constraints/constraints-holder/guards-holder/text-guard/text-guard.component';
import { TextDateInputModule } from '@domains/directives/text-date-input-directive';
import { SlugStringifyPipeModule } from '@domains/pipes/slug-stringify.pipe';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    NgSelectModule,
    NgOptionHighlightModule,
    SlugStringifyPipeModule,
    MatSelectModule,
    TextDateInputModule,
    MatButtonModule,
  ],
  exports: [GuardsHolderComponent],
  declarations: [
    GuardsHolderComponent,
    SchoolGuardComponent,
    CityGuardComponent,
    ChoiceGuardComponent,
    NumberGuardComponent,
    DateGuardComponent,
    TextGuardComponent,
    SelectGuardComponent,
  ],
  providers: [],
})
export class GuardsHolderModule {}
