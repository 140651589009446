import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-restore-revision-dialog',
  templateUrl: './restore-revision-dialog.component.html',
  styleUrls: ['./restore-revision-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestoreRevisionDialogComponent {
  constructor(public dialogRef: MatDialogRef<RestoreRevisionDialogComponent, boolean>) {}

  cancel(): void {
    this.dialogRef.close(false);
  }

  approve(): void {
    this.dialogRef.close(true);
  }
}
