import { Injectable } from '@angular/core';
import { GeoRegion } from '@models/commons/geo-regions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetContinents, GetCountries, GetDepartments, GetStates } from '@stores/locations/locations.actions';
import { LocationWebservice } from '@webservices/location-api/location.webservice';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface LocationsStateModel {
  departments: GeoRegion[];
  states: GeoRegion[];
  countries: string[];
  continents: string[];
}

@State<LocationsStateModel>({
  name: 'locations',
  defaults: {
    continents: [],
    countries: [],
    departments: [],
    states: [],
  },
})
@Injectable()
export class LocationsState {
  @Selector()
  static departments(state: LocationsStateModel): GeoRegion[] {
    return state.departments;
  }

  @Selector()
  static continents(state: LocationsStateModel): string[] {
    return state.continents;
  }
  @Selector()
  static countries(state: LocationsStateModel): string[] {
    return state.countries;
  }
  @Selector()
  static states(state: LocationsStateModel): GeoRegion[] {
    return state.states;
  }

  constructor(private readonly locationWebservice: LocationWebservice) {}

  @Action(GetDepartments)
  getDepartments(context: StateContext<LocationsStateModel>): Observable<GeoRegion[]> {
    const state = context.getState();
    if (state.departments.length) {
      return of(state.departments);
    }
    return this.locationWebservice.getDepartments().pipe(
      tap((values) => {
        context.patchState({
          departments: values,
        });
      })
    );
  }
  @Action(GetContinents)
  getContinents(context: StateContext<LocationsStateModel>): Observable<string[]> {
    const state = context.getState();
    if (state.continents.length) {
      return of(state.continents);
    }
    return this.locationWebservice.getContinents().pipe(
      tap((values) => {
        context.patchState({
          continents: values,
        });
      })
    );
  }
  @Action(GetCountries)
  getCountries(context: StateContext<LocationsStateModel>): Observable<string[]> {
    const state = context.getState();
    if (state.countries.length) {
      return of(state.countries);
    }
    return this.locationWebservice.getCountries().pipe(
      tap((values) => {
        context.patchState({
          countries: values,
        });
      })
    );
  }
  @Action(GetStates)
  getStates(context: StateContext<LocationsStateModel>): Observable<GeoRegion[]> {
    const state = context.getState();

    if (state.states.length) {
      return of(state.states);
    }

    return this.locationWebservice.getStates().pipe(
      tap((values) => {
        context.patchState({
          states: values,
        });
      })
    );
  }
}
