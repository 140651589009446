import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from '@domains/not-found/not-found/not-found.component';
import { ResourceNotFoundComponent } from '@domains/not-found/resource-not-found/resource-not-found.component';

@NgModule({
  declarations: [ResourceNotFoundComponent, NotFoundComponent],
  imports: [CommonModule, RouterModule, MatIconModule],
  exports: [ResourceNotFoundComponent, NotFoundComponent],
})
export class NotFoundModule {}
