import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, NgModule, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DependenciesService } from '@core/services/dependencies.service';
import { GuardsHolderModule } from '@domains/constraints/constraints-holder/guards-holder/guards-holder.module';
import {
  AllowedConstraintTypesByDataType,
  AllowedConstraintTypesByDependencyType,
  ConstraintType,
  ConstraintTypeLabels,
  FrontConstraint,
} from '@models/constraint';
import { PickableDependencyItem } from '@models/pickable-dependency-item';
import { LabelByQuestionType } from '@models/question';
import { LabelByTransformerType, Transformer } from '@models/transformer';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxsFormPluginModule, UpdateFormValue } from '@ngxs/form-plugin';
import { Store } from '@ngxs/store';
import { SuggestionWebservice } from '@webservices/suggestion-api/suggestion.webservice';
import { Observable, combineLatest } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';

@Component({
  templateUrl: './constraint-dialog.component.html',
  styleUrls: ['./constraint-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConstraintDialogComponent implements OnInit, AfterViewInit {
  get dependencyIdControl(): UntypedFormControl {
    return (this.constraintFormGroup.get('dependency') as UntypedFormGroup).get('id') as UntypedFormControl;
  }

  get constraintNegation(): boolean {
    return this.constraintFormGroup.get('isNot')?.value;
  }

  get currentConstraintType(): ConstraintType {
    return this.constraintFormGroup.get('type')?.value;
  }

  get isMultipleValues(): boolean {
    return this.currentConstraintType === ConstraintType.AnyOf;
  }

  get guardControl(): UntypedFormControl {
    return this.constraintFormGroup.get('guard') as UntypedFormControl;
  }

  get acceptedValuesControl(): UntypedFormControl {
    return this.constraintFormGroup.get('acceptedValues') as UntypedFormControl;
  }

  get dependency(): FormGroup {
    return this.constraintFormGroup.get('dependency') as FormGroup;
  }

  get dependencyItems$(): Observable<PickableDependencyItem[] | undefined> {
    return this.#dependenciesService.dependencies$.asObservable();
  }

  constraintFormGroup: UntypedFormGroup;

  constraintTypeLabels = ConstraintTypeLabels;
  questionTypeLabels = LabelByQuestionType;
  constraintsByDep = AllowedConstraintTypesByDependencyType;
  constraintByDataType = AllowedConstraintTypesByDataType;
  transformerTypeLabels = LabelByTransformerType;

  constraintFormGroupChanges$!: Observable<FrontConstraint>;
  isQuestionOrNonSequenceTransformer$!: Observable<any>;
  currentDependency$!: Observable<PickableDependencyItem | undefined>;
  transformer$?: Observable<Transformer>;

  readonly #destroyRef = inject(DestroyRef);
  readonly #formBuilder = inject(UntypedFormBuilder);
  readonly #dependenciesService = inject(DependenciesService);
  readonly #suggestionWebService = inject(SuggestionWebservice);
  readonly #store = inject(Store);
  searchDependencies$!: Observable<PickableDependencyItem[] | undefined>;

  dialogRef = inject(MatDialogRef<ConstraintDialogComponent, boolean>);
  data: { constraintIndex: number; path: string; formData: FrontConstraint } = inject(MAT_DIALOG_DATA);
  constructor() {
    this.constraintFormGroup = this.#formBuilder.group({
      isNot: [undefined],
      type: [undefined],
      guard: [undefined],
      acceptedValues: [undefined],
      dependency: this.#formBuilder.group({
        id: undefined,
        type: undefined,
      }),
    });

    combineLatest([
      this.dependencyIdControl.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)),
      this.#dependenciesService.dependenciesLoaded$,
    ])
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        filter(([_, dependenciesLoaded]) => dependenciesLoaded)
      )
      .subscribe(([value, _]) =>
        this.constraintFormGroup
          .get('dependency')
          ?.get('type')
          ?.setValue(this.#dependenciesService.get(value)?.resourceType)
      );

    this.dependencyIdControl.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((value) => {
      if (value) {
        this.constraintFormGroup.get('type')?.enable();
      } else {
        this.constraintFormGroup.get('type')?.disable();
      }
    });

    this.constraintFormGroupChanges$ = this.constraintFormGroup.valueChanges.pipe(
      takeUntilDestroyed(this.#destroyRef),
      shareReplay(1)
    );

    // Warning: it's required to init first subscription of constraintFormGroupChanges
    this.constraintFormGroupChanges$.subscribe();
  }

  ngOnInit(): void {
    this.transformer$ = this.constraintFormGroup.get('dependency')?.valueChanges.pipe(
      startWith(this.constraintFormGroup.value.dependency),
      filter((dependency) => dependency.type === 'transformer'),
      map((dependency) => dependency.id),
      switchMap((dependencyId) => this.#suggestionWebService.getTransformer(dependencyId)),
      shareReplay(1)
    );

    if (this.data.formData.type) {
      this.constraintFormGroup.setValue(this.data.formData);
    }

    this.searchDependencies$ = this.dependency.get('id').valueChanges.pipe(
      startWith(''),
      takeUntilDestroyed(this.#destroyRef),
      debounceTime(300),
      switchMap((value) => this.#dependenciesService.getDependencies(value)),
      filter((dependencies) => !!dependencies),
      shareReplay(1)
    );
  }

  ngAfterViewInit(): void {
    this.constraintFormGroup
      .get('dependency')
      ?.valueChanges.pipe(
        startWith({ type: null }),
        takeUntilDestroyed(this.#destroyRef),
        filter((value) => !!value.type),
        map((value) => value.type),
        pairwise()
      )
      .subscribe(() => {
        this.constraintFormGroup.get('type')?.reset();
        this.constraintFormGroup.get('guard')?.setValue(null);
        this.constraintFormGroup.get('acceptedValues')?.setValue(null);
      });

    this.constraintFormGroup
      .get('type')
      ?.valueChanges.pipe(
        takeUntilDestroyed(this.#destroyRef),
        filter((value) => !!value),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.constraintFormGroup.get('guard')?.setValue(null);
        this.constraintFormGroup.get('acceptedValues')?.setValue(null);
      });

    this.currentDependency$ = combineLatest([
      this.searchDependencies$,
      this.constraintFormGroupChanges$.pipe(
        map((constraint) => constraint.dependency.id),
        distinctUntilChanged()
      ),
    ]).pipe(
      map(([dependencyItems, value]: [PickableDependencyItem[] | undefined, string | undefined]) =>
        dependencyItems?.find((i) => i.id === value)
      ),
      shareReplay(1)
    );

    this.isQuestionOrNonSequenceTransformer$ = this.currentDependency$.pipe(
      takeUntilDestroyed(this.#destroyRef),
      filter((currentDependency) => !!currentDependency),
      map(
        (currentDependency) =>
          currentDependency?.resourceType === 'question' || currentDependency?.resourceType === 'transformer'
      )
    );
  }

  onSubmit(): void {
    if (this.constraintFormGroup.invalid) {
      return;
    }

    if (this.data.path) {
      this.#store.dispatch(
        new UpdateFormValue({
          value: this.constraintFormGroup.value,
          path: this.data.path,
          propertyPath: `constraint.${this.data.constraintIndex}`,
        })
      );
    }

    this.dialogRef.close(this.constraintFormGroup.value);
  }

  toggleConstraintNegation(): void {
    this.constraintFormGroup.get('isNot')?.setValue(!this.constraintNegation);
  }

  trackByValue(_: number, value: string): string {
    return value;
  }

  trackByPickableDependencyItem(_: number, item: PickableDependencyItem): string {
    return item.id;
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    NgSelectModule,
    NgOptionHighlightModule,
    ReactiveFormsModule,
    GuardsHolderModule,
    NgxsFormPluginModule,
    MatIconModule,
    MatIconModule,
    MatAutocompleteModule,
    MatInputModule,
  ],
  declarations: [ConstraintDialogComponent],
})
export class ConstraintDialogModule {}
