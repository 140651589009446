import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginTokenGuard } from '@core/guards/login-token.guard';
import { NotLoggedGuard } from '@core/guards/not-logged.guard';
import { NotFoundModule } from '@domains/not-found/not-found.module';
import { NotFoundComponent } from '@domains/not-found/not-found/not-found.component';
import { ResourceNotFoundComponent } from '@domains/not-found/resource-not-found/resource-not-found.component';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/features-routing.enum';
import {
  DashboardLayoutComponent,
  DashboardLayoutModule,
} from '@features/layouts/dashboard-layout/dashboard-layout.component';
import { MustBeAuthorizedGuard } from '@wizbii-components/bo-angular-ui';
import {
  AUTHENTICATION_API_CONFIG,
  AuthenticationWebservice,
  CityWebservice,
  PLACE_API_CONFIG,
} from '@wizbii-utils/angular/webservices';

@NgModule({
  imports: [
    NotFoundModule,

    RouterModule.forChild([
      {
        path: '',
        canActivate: [NotLoggedGuard, LoginTokenGuard, MustBeAuthorizedGuard],
        component: DashboardLayoutComponent,
        children: [
          {
            path: FeaturesRoutingEnum.Themes,
            loadChildren: () => import('./themes/themes.module').then((m) => m.ThemeModule),
          },
          {
            path: FeaturesRoutingEnum.Questions,
            loadChildren: () => import('./questions/questions.module').then((m) => m.QuestionsModule),
          },
          {
            path: FeaturesRoutingEnum.Transformers,
            loadChildren: () => import('./transformers/transformers.module').then((m) => m.TransformersModule),
          },
          {
            path: FeaturesRoutingEnum.Suggestions,
            loadChildren: () => import('./suggestions/suggestions.module').then((m) => m.SuggestionsModule),
          },
          {
            path: FeaturesRoutingEnum.Product,
            loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule),
          },
          {
            path: FeaturesRoutingEnum.Home,
            loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
          },
          {
            path: FeaturesRoutingEnum.NotFound,
            component: ResourceNotFoundComponent,
          },
        ],
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ]),
    DashboardLayoutModule,
  ],
  providers: [
    {
      provide: PLACE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.place,
      },
    },
    CityWebservice,

    {
      provide: AUTHENTICATION_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.authentication,
      },
    },
    AuthenticationWebservice,
  ],
})
export class FeaturesModule {}
